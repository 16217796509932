import React, { useContext } from "react";
import { Box, Button, Flex, Spacer } from "@chakra-ui/react";
import { ColorLogo } from "../index";
import en from "../../lib/en.json";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

const Header = () => {
  const {
    startCreation,
    setStartCreationActive,
    resetMap,
    resetStrokeWidth,
  } = useContext(SectorContext) as UpdateDataContextType;

  const startMapping = () => {
    setStartCreationActive(true);
    resetMap();
    resetStrokeWidth();
  };
  
  const displayButton = () => {
    if (!startCreation) {
      return (
        <Button variant="yellow" onClick={startMapping}>
          {en.common.createYourOwn}
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <Box mb="26px" w={"100%"}>
      <Flex>
        <ColorLogo />
        <Spacer />
        {displayButton()}
      </Flex>
    </Box>
  );
};

export default Header;
