import en from "./en.json";
import { ComponentWithAs, IconProps } from "@chakra-ui/react";
import {
  DataIconMedium,
  DataIconSmall,
  FundingIconMedium,
  FundingIconSmall,
  PeopleIconMedium,
  PeopleIconSmall,
  PolicyIconMedium,
  PolicyIconSmall,
  ProductsIconMedium,
  ProductsIconSmall,
} from "../components/Icons/Icons";
import { Props as AnchorLinkProps } from "../design-system/atoms/AnchorLink/AnchorLink";
import { Props as RoleDescriptionItemProps } from "../components/RoleDescriptions/RoleItem";
import { Props as InspirationAccordionItemProps } from "../components/InspirationAccordion/InspirationAccordionItem";

export const tabNavigationLinks: AnchorLinkProps[] = [
  {
    href: "#about",
    text: "About",
  },
  {
    href: "#tutorial",
    text: "Tutorial",
  },
  {
    href: "#inspiration",
    text: "Inspiration",
  },
  {
    href: "#toolkit",
    text: "Toolkit",
  },
];

export const backNavigationLinks: AnchorLinkProps[] = [
  {
    href: "/",
    text: en.common.back,
  },
];

export const roleIcons = {
  dataMedium: DataIconMedium,
  dataSmall: DataIconSmall,
  fundingMedium: FundingIconMedium,
  fundingSmall: FundingIconSmall,
  peopleMedium: PeopleIconMedium,
  peopleSmall: PeopleIconSmall,
  policyMedium: PolicyIconMedium,
  policySmall: PolicyIconSmall,
  productsMedium: ProductsIconMedium,
  productsSmall: ProductsIconSmall,
};

export const roleDescriptionItems: RoleDescriptionItemProps[] = [
  {
    headerText: en.landingPage.rolesAccordion.people.header,
    descriptionText: en.landingPage.rolesAccordion.people.description,
    icon: PeopleIconMedium,
  },
  {
    headerText: en.landingPage.rolesAccordion.funding.header,
    descriptionText: en.landingPage.rolesAccordion.funding.description,
    icon: FundingIconMedium,
  },
  {
    headerText: en.landingPage.rolesAccordion.dataAndInformation.header,
    descriptionText: en.landingPage.rolesAccordion.dataAndInformation.description,
    icon: DataIconMedium,
  },
  {
    headerText: en.landingPage.rolesAccordion.policy.header,
    descriptionText: en.landingPage.rolesAccordion.policy.description,
    icon: PolicyIconMedium,
  },
  {
    headerText: en.landingPage.rolesAccordion.productsAndServices.header,
    descriptionText: en.landingPage.rolesAccordion.productsAndServices.description,
    icon: ProductsIconMedium,
  },
];

export const inspirationAccordionItems: InspirationAccordionItemProps[] = [
  {
    headerText: en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.header,
    descriptionText:
      en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.description,
    keyIssueAreaItems: [
      {
        descriptionText:
          en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.keyIssueAreas
            .keyIssueArea1.description,
        sources: [
          {
            source:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea1.source,
            sourceLink:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea1.sourceLink,
          },
        ],
      },
      {
        descriptionText:
          en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.keyIssueAreas
            .keyIssueArea2.description,
        sources: [
          {
            source:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea2.source,
            sourceLink:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea2.sourceLink,
          },
        ],
      },
      {
        descriptionText:
          en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.keyIssueAreas
            .keyIssueArea3.description,
        sources: [
          {
            source:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea3.source,
            sourceLink:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea3.sourceLink,
          },
        ],
      },
      {
        descriptionText:
          en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders.keyIssueAreas
            .keyIssueArea4.description,
        sources: [
          {
            source:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea4.source,
            sourceLink:
              en.landingPage.needSomeInspiration.inspirationAccordion.uptakeWithProviders
                .keyIssueAreas.keyIssueArea4.sourceLink,
          },
        ],
      },
    ],
  },
];

export const arcIconRoleMapping: Record<string, ComponentWithAs<"svg", IconProps>> = {
  "Data and Information": roleIcons.dataMedium,
  Funding: roleIcons.fundingMedium,
  People: roleIcons.peopleMedium,
  Policy: roleIcons.policyMedium,
  "Products and Services": roleIcons.productsMedium,
};

export const arcIconRoleMappingSmall: Record<string, ComponentWithAs<"svg", IconProps>> = {
  "Data and Information": roleIcons.dataSmall,
  Funding: roleIcons.fundingSmall,
  People: roleIcons.peopleSmall,
  Policy: roleIcons.policySmall,
  "Products and Services": roleIcons.productsSmall,
};
