import React, { useContext } from "react";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { Box, Divider, Flex, Spacer, Text } from "@chakra-ui/react";
import { Header, MappingToolOverview, MappingToolCreation } from "../../components";
import CombinationDonutPieChart from "../../charts/CombinationDonutPieChart/CombinationDonutPieChart";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import SectorDetails from "../../components/SectorDetails/SectorDetails";
import en from "../../lib/en.json";

function Home() {
  const { data, selectedSector, startCreation } = useContext(
    SectorContext,
  ) as UpdateDataContextType;

  const innerSectorData = data.flatMap((sector) => sector.sectors);

  const setDisplayContent = () => {
    if (startCreation) {
      return <MappingToolCreation />;
    } else if (!startCreation && selectedSector) {
      return <SectorDetails />;
    } else {
      return <MappingToolOverview />;
    }
  };

  return (
    <Box sx={styles.container}>
      <Header />
      <Flex sx={styles.contentContainer}>
        <Box sx={{ ...styles.horizontalContentContainer, ...styles.overviewContentContainer }}>
          {setDisplayContent()}
        </Box>
        <Spacer />
        <Divider orientation="vertical" height="85vh" borderColor="gray10" />
        <Spacer />
        <Flex sx={styles.horizontalContentContainer} w={"60vw"}>
          {!startCreation && (
            <Box sx={styles.clickHeader}>
              <Text variant={"upperCaseHeader"}>{en.common.clickWheel}</Text>
            </Box>
          )}
          <ParentSize>
            {({ width, height }) => (
              <CombinationDonutPieChart
                width={width}
                height={height}
                donutData={data}
                outerPieData={innerSectorData}
                downloadModal={false}
              />
            )}
          </ParentSize>
        </Flex>
      </Flex>
      <Box pt="5px">
        <Text variant={"disclaimer"}>{en.common.disclaimer}</Text>
      </Box>
    </Box>
  );
}

export default Home;

const styles = {
  container: {
    padding: "22px 32px",
    width: "100vw",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    border: "1px solid gray10",
    boxShadow: "0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 8px 16px -1px rgba(5, 28, 44, 0.20)",
    width: "100%",
  },
  horizontalContentContainer: {
    display: "flex",
    flexDirection: "column",
    height: "85vh",
    padding: "12px 0px",
  },
  overviewContentContainer: {
    overflowY: "hidden",
    scrollPaddingTop: "4rem",
    w: "40%",
  },
  clickHeader: {
    position: "relative",
    right: "2%",
  },
};
