import React, { useContext } from "react";
import { Box, Button, HStack, Image, Text } from "@chakra-ui/react";
import CombinationDonutPieChart from "../../charts/CombinationDonutPieChart/CombinationDonutPieChart";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import en from "../../lib/en.json";
import {
  DataIconMedium,
  FundingIconMedium,
  PeopleIconMedium,
  PolicyIconMedium,
  ProductsIconMedium,
  SelectedIcon,
} from "../Icons/Icons";
import { RoleLegendHorizontal } from "../../charts/RoleLegend/RoleLegend";
import MappingNotes from "../MappingNotes/MappingNotes";
import { InnerSectorData } from "../../utils/data";

type Props = {
  contentRef: React.MutableRefObject<null>;
  chunkedNotesArray: InnerSectorData[][];
};

const PDFOutput = ({ contentRef, chunkedNotesArray }: Props) => {
  const { data, mapState, defineIssue } = useContext(SectorContext) as UpdateDataContextType;

  const innerSectorData = data.flatMap((sector) => sector.sectors);

  return (
    <div ref={contentRef}>
      <div id="pdfPage">
        <Box sx={styles.pageOne}>
          <Image
            src={require("../../assets/images/tkf_logo_hi_res.png")}
            htmlWidth="165px"
            htmlHeight="48px"
          />
          <Box sx={styles.yourSystemMapContainer}>
            <Text sx={styles.titleGray}>{en.pdfOutput.title}</Text>
            <Text sx={styles.bodyGray}>
              {en.pdfOutput.issueLabel}: {defineIssue}
            </Text>
          </Box>
        </Box>
      </div>
      <div id="pdfPage">
        <div ref={contentRef}>
          <Box sx={styles.pageTwo}>
            <Text sx={styles.titleBlack}>{en.creationPage.issue.header}</Text>
            <Text sx={styles.bodyBlack}>{en.creationPage.issue.body}</Text>
            <Image sx={styles.divider} src={require("../../assets/images/divider.png")} />
            <Text sx={styles.bodyGray}>{en.pdfOutput.yourAnswer}:</Text>
            <Text sx={styles.bodyBlack}>{defineIssue}</Text>
            <Text sx={styles.titleBlack}>{en.creationPage.mapState.header}</Text>
            <Text sx={styles.bodyBlack}>{en.creationPage.mapState.body}</Text>
            <Image sx={styles.divider} src={require("../../assets/images/divider.png")} />
            <Text sx={styles.bodyGray}>{en.pdfOutput.yourAnswer}:</Text>
            <Button
              sx={styles.stateSelectedButton}
              leftIcon={
                <HStack spacing="8px" marginTop="10px">
                  <SelectedIcon w="27px" h="24px" />
                </HStack>
              }
            >
              {mapState}
            </Button>
          </Box>
        </div>
      </div>
      <div id="pdfPage">
        <div ref={contentRef}>
          <Box sx={styles.pageThree}>
            <Text sx={styles.titleBlack}>{en.landingPage.subtitle}</Text>
            <Text sx={styles.bodyBlack}>{en.creationPage.startMapping.body}</Text>
            <Image sx={styles.divider} src={require("../../assets/images/divider.png")} />
            <Text sx={styles.bodyGray}>{en.pdfOutput.yourGraph}:</Text>
            <CombinationDonutPieChart
              width={515}
              height={467}
              donutData={data}
              outerPieData={innerSectorData}
              downloadModal
            />
            <RoleLegendHorizontal />
          </Box>
        </div>
      </div>

      {chunkedNotesArray.map((chunk: InnerSectorData[], index: number) => {
        index += 2;
        return (
          <div id="pdfPage" key={index}>
            <div ref={contentRef}>
              <Box sx={styles.pageFour}>
                <Text sx={styles.titleBlack}>{en.pdfOutput.yourNotes}</Text>
                {chunk.map((item: InnerSectorData) => {
                  return (
                    <Box sx={styles.sectorContainer} key={item.title}>
                      <Text>{item.title}</Text>
                      <Box flexWrap="wrap">
                        {item.rolesSelected.length > 0 &&
                          item.rolesSelected.map((roleTag: string) => {
                            return (
                              <Button
                                key={roleTag}
                                sx={styles.roleTagsSelectedButton}
                                leftIcon={
                                  <HStack spacing="8px">
                                    <SelectedIcon w="24px" h="21px" />
                                    {(roleTag === "People" && (
                                      <PeopleIconMedium w="20px" h="20px" />
                                    )) ||
                                      (roleTag === "Funding" && (
                                        <FundingIconMedium w="20px" h="20px" />
                                      )) ||
                                      (roleTag === "Data and Information" && (
                                        <DataIconMedium w="20px" h="20px" />
                                      )) ||
                                      (roleTag === "Products and Services" && (
                                        <ProductsIconMedium w="20px" h="20px" />
                                      )) ||
                                      (roleTag === "Policy" && (
                                        <PolicyIconMedium w="20px" h="20px" />
                                      ))}
                                  </HStack>
                                }
                              >
                                <Text variant="roleTags" key={roleTag}>
                                  {roleTag}
                                </Text>
                              </Button>
                            );
                          })}
                      </Box>
                      <MappingNotes id={item.id} pdfOutput />
                    </Box>
                  );
                })}
              </Box>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PDFOutput;

const styles = {
  divider: {
    color: "gray30",
    width: "515px",
    height: "0.5px",
  },
  pageOne: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "842px",
    width: "595px",
    gap: "28px",
    paddingTop: "50%",
  },
  yourSystemMapContainer: {
    alignItems: "center",
    background: "primaryYellow",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    minHeight: "120px",
    width: "595px",
  },
  titleGray: {
    color: "gray60",
    fontSize: "20px",
    paddingTop: "10px",
    textAlign: "center",
  },
  bodyGray: {
    color: "gray60",
    fontSize: "14px",
    paddingBottom: "10px",
    width: "400px",
  },
  pageTwo: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    height: "842px",
    width: "595px",
    gap: "10px",
    padding: "30px",
    textAlign: "left",
  },
  titleBlack: {
    color: "black",
    fontSize: "20px",
    paddingTop: "10px",
    textAlign: "left",
    width: "376px",
  },
  titleBlackSmall: {
    color: "black",
    fontSize: "16px",
    paddingTop: "10px",
    textAlign: "left",
    width: "376px",
  },
  bodyBlack: {
    color: "black",
    fontSize: "14px",
    textAlign: "left",
    paddingBottom: "10px",
    width: "515px",
  },
  stateSelectedButton: {
    alignItems: "start",
    backgroundColor: "white",
    border: "1px solid black",
    borderRadius: "8px",
    fontWeight: 700,
    justifyContent: "flex-start",
    pb: "2rem",
    pt: "1rem",
    width: "515px",
  },
  pageThree: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    height: "842px",
    width: "595px",
    gap: "10px",
    padding: "30px",
    textAlign: "left",
  },
  pageFour: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    padding: "30px",
    textAlign: "left",
    width: "595px",
  },
  sectorContainer: {
    border: "1px solid",
    borderColor: "gray10",
    borderRadius: "8px",
    display: "flex",
    mb: "0.5rem",
    padding: "8px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    width: "515px",
    maxHeight: "220px",
  },
  roleTagsSelectedButton: {
    height: "2rem",
    border: "1px solid black",
    borderRadius: "8px",
    fontSize: "10px",
    fontWeight: 700,
    mb: "0.25rem",
  },
};
