import React from "react";
import { Link } from "@chakra-ui/react";

export type Props = {
  href: string;
  text: string;
  download?: boolean;
};

const AnchorLink = ({ href, text, download }: Props) => {
  return (
    <Link href={href} sx={download ? styles.downloadLink : styles.link}>
      {text}
    </Link>
  );
};

export default AnchorLink;

const styles = {
  link: {
    textAlign: "center",
    fontSize: ["0.625rem", "0.75rem", "0.875rem"],
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "0.105px",
    p: "4px",
    pb: "0px",
    _hover: {
      textDecoration: "none",
    },
  },
  downloadLink: {
    fontSize: ["0.5rem", "0.625rem", "0.75rem"],
    textDecoration: "underline",
    _hover: {
      cursor: "pointer",
    },
  },
};
