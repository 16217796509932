const Text = {
  baseStyle: {
    color: "gray90",
    fontSize: ["0.75rem", "0.875rem", "1rem"],
    fontWeight: 400,
    lineHeight: "140%",
  },
  variants: {
    header0Bold: {
      fontSize: ["1.5rem", "1.75rem", "2rem"],
      fontWeight: 700,
      letterSpacing: "-0.176px",
      lineHeight: "120%",
    },
    header0: {
      fontSize: ["1.5rem", "1.75rem", "2rem"],
      fontWeight: 400,
      letterSpacing: "-0.176px",
      lineHeight: "120%",
    },
    header1: {
      fontSize: ["1.125rem", "1.25rem", "1.5rem"],
      fontWeight: 700,
      letterSpacing: "-0.132px",
      lineHeight: "110%",
      m: "0.5rem 0rem",
      p: "0.5rem 0rem",
    },
    header3: {
      fontWeight: 700,
      lineHeight: "140%",
    },
    header4: {
      fontSize: ["0.625rem", "0.75rem", "0.875rem"],
      fontWeight: 500,
      lineHeight: "1.225rem",
      textAlign: "left",
    },
    header5: {
      fontSize: ["0.5rem", "0.625rem", "0.75rem"],
      fontWeight: 500,
      letterSpacing: "1.5px",
      lineHeight: "100%",
    },
    paragraph1: {
      m: "12px 0px",
    },
    paragraph2: {
      fontSize: ["0.625rem", "0.75rem", "0.875rem"],
    },
    paragraph3: {
      fontSize: ["0.75rem", "0.875rem", "1rem"],
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "140%",
    },
    paragraph4: {
      fontSize: ["0.5rem", "0.625rem", "0.75rem"],
      fontWeight: 500,
      color: "black",
      alignText: "left",
    },
    sourceLink: {
      fontSize: ["0.5rem", "0.625rem", "0.75rem"],
    },
    notes: {
      fontSize: ["0.75rem", "0.875rem", "1rem"],
      fontWeight: 400,
      lineHeight: "140%",
      color: "gray60",
      alignText: "left",
    },
    roleTags: {
      fontSize: ["0.75rem", "0.781rem", "0.8125rem"],
      fontWeight: 600,
      lineHeight: "1.5rem",
      letterSpacing: "0.01625rem",
      color: "gray800",
    },
    upperCaseHeader: {
      alignText: "left",
      fontSize: ["0.5rem", "0.625rem", "0.75rem"],
      fontWeight: 500,
      lineHeight: "100%",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
    },
    upperCaseHeaderGray: {
      alignText: "left",
      color: "gray60",
      fontSize: ["0.5rem", "0.625rem", "0.75rem"],
      fontWeight: 500,
      lineHeight: "100%",
      letterSpacing: "1.5px",
      textTransform: "uppercase",
    },
    goBackEdit: {
      color: "gray60",
      fontSize: ["0.625rem", "0.75rem", "0.875rem"],
      fontWeight: 500,
      _hover: {
        cursor: "pointer",
      },
    },
    disclaimer: {
      color: "gray60",
      fontSize: "10px",
      textAlign: "left",
    },
  },
};

export default Text;
