import { extendTheme } from "@chakra-ui/react";
import Button from "./components/Button";
import Text from "./components/Text";

const colors = {
  white: "#FFFFFF",
  gray10: "#E6E6E6",
  gray15: "#E5E5E6",
  gray20: "#D9D4D4",
  gray30: "#B3B3B3",
  gray40: "#999999",
  gray54: "#757575",
  gray60: "#656565",
  gray70: "#4D4D4D",
  gray80: "#333333",
  gray90: "#21272A",
  gray200: "#C1C1C1",
  gray800: "#282828",
  black: "#000000",
  lightPrimaryYellow: "#FFF7CF",
  primaryYellow: "#F7DD57",
  darkPurple: "#4C0389",
  lightPurple: "#D7C8E3",
  lightPurple75: "#E1D6EA",
  lightPurple50: "#EBE3F1",
  lightPurple25: "#F5F1F8",
  darkBlueLabel: "#0058A9",
  darkBlue: "#0084FF",
  lightBlue: "#C7D6E4",
  lightBlue75: "#D5E0EB",
  lightBlue50: "#E3EBF1",
  darkTealLabel: "#0B7B75",
  darkTeal: "#22ADA7",
  lightTeal: "#B5E1DF",
  lightTeal75: "#C7E9E7",
  lightTeal50: "#DAF0EF",
  lightTeal25: "#EDF7F7",
  darkGreenLabel: "#256A00",
  darkGreen: "#419B10",
  lightGreen: "#CEEABF",
  lightGreen75: "#DAEFCF",
  lightGreen50: "#E7F5DF",
  lightGreen25: "#F3FAEF",
  darkOrangeLabel: "#955A09",
  darkOrange: "#C57509",
  lightOrange: "#FEE7C8",
  lightOrange75: "#FEEDD6",
  lightOrange50: "#FFF3E3",
  lightOrange25: "#FFF9F1",
};

const theme = {
  styles: {
    global: {
      "*": {
        boxSizing: "border-box",
        fontFamily: "Inter",
      },
      html: {
        fontWeight: 400,
        fontSize: "0.875rem",
        margin: 0,
        minHeight: "100vh",
      },
      body: {
        overflow: "auto",
      },
      a: {
        textDecoration: "none",
      },
      img: {
        maxWidth: "100%",
      },
    },
  },
  colors: colors,
  components: {
    Button,
    Text,
  },
};

export default extendTheme(theme);
