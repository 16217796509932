import React from "react";
import { HStack } from "@chakra-ui/react";
import AnchorLink, { Props as AnchorLinkProps } from "../../atoms/AnchorLink/AnchorLink";

export interface Props {
  navLinks: AnchorLinkProps[];
}

const TabAnchorLinkNavigation = ({ navLinks }: Props) => {
  return (
    <HStack spacing="1.25rem" sx={styles}>
      {navLinks.map((link) => {
        return <AnchorLink href={link.href} text={link.text} key={link.text} />;
      })}
    </HStack>
  );
};

export default TabAnchorLinkNavigation;

const styles = {
  backgroundColor: "white",
  borderBottom: "2px solid",
  borderColor: "gray20",
  height: "2.75rem",
  mb: "26px",
  ml: "56px",
  mr: "56px",
  position: "sticky",
  top: 0,
  "& > *:hover": {
    borderBottom: "2px solid",
    borderColor: "black",
    mb: "-10px",
    pb: "8px",
  },
};
