import React, { useState, useContext } from "react";
import { Box, Button, HStack, Text, ButtonGroup, useDisclosure } from "@chakra-ui/react";
import * as htmlToImage from "html-to-image";
import jsPDF from "jspdf";
import { OverviewSection, TextBox } from "../../design-system/index";
import en from "../../lib/en.json";
import CreationAccordion from "../CreationAccordion/CreationAccordion";
import { SelectedIcon } from "../Icons/Icons";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import DownloadModal from "../DownloadModal/DownloadModal";
import WarningModal from "../WarningModal/WarningModal";

const MappingToolCreation = () => {
  const [active, setActive] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const {
    resetMap,
    setExpandedAccordionIndices,
    setStartCreationActive,
    resetStrokeWidth,
    defineIssue,
    setDefineIssue,
    setMapState,
  } = useContext(SectorContext) as UpdateDataContextType;
  const downloadModal = useDisclosure();

  const {
    isOpen: warningModalIsOpen,
    onOpen: warningModalOpen,
    onClose: warningModalClose,
  } = useDisclosure();

  const handleWarningModal = () => {
    downloadModal.onClose();
    warningModalOpen();
  };

  const resetMappingToolCreation = (mapToolStatus: string) => {
    if (mapToolStatus === "closeMapTool") {
      setStartCreationActive(false);
    } else {
      setStartCreationActive(true);
    }
    setDefineIssue("");
    setActive(0);
    setMapState("");
    setExpandedAccordionIndices([]);
    resetMap();
    resetStrokeWidth();
  };

  const setMapStateButton = (stringValue: string, index: number) => {
    setMapState(stringValue);
    setActive(index);
  };

  const pdf = new jsPDF("p", "px");
  const options = {
    quality: 1.0,
    width: 595,
    height: 842,
    cacheBust: true,
  };

  const captureElementAsImage = (element: HTMLElement) => {
    return htmlToImage
      .toPng(element, options)
      .then(function (dataUrl) {
        return dataUrl; // Return the captured image data URL
      })
      .catch(function (error) {
        console.error("Error capturing image:", error);
        return null;
      });
  };

  const createMultiPagePDF = async () => {
    setIsDownloading(true)
    const elementsToCapture: NodeListOf<HTMLElement> = document.querySelectorAll("#pdfPage")!;
    for (const element of elementsToCapture) {
      const image = await captureElementAsImage(element);
      if (image) {
        const imgProps = pdf.getImageProperties(image);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(image, "PNG", 0, 0, pdfWidth, pdfHeight, "", "SLOW");
        if (element !== elementsToCapture[elementsToCapture.length - 1]) {
          pdf.addPage(); // Add a new page for the next image
        }
      }
    }
    // Save or display the PDF
    pdf.save("systems_mapping_output.pdf");
    setIsDownloading(false);
    downloadModal.onClose();
    warningModalClose();
  };

  return (
    <>
      <Box h="90%">
        <Box sx={styles.headerContainer}>
          <Text variant="header4">{en.creationPage.title}</Text>
          <Text variant="header4" onClick={() => resetMappingToolCreation("closeMapTool")}>
            X {en.common.cancel}
          </Text>
        </Box>
        <Box sx={styles.overviewContainer}>
          {/* What issue do you want to map? */}
          <OverviewSection
            header={en.creationPage.issue.header}
            body1={en.creationPage.issue.body}
          />
          <TextBox
            placeholder={en.creationPage.issue.placeholder}
            defineIssue={defineIssue}
            setDefineIssue={setDefineIssue}
          />
          {/* Are you thinking about the present or the future? */}
          <OverviewSection
            header={en.creationPage.mapState.header}
            body1={en.creationPage.mapState.body}
          />
          <ButtonGroup sx={styles.mapStateButtonContainer}>
            <Button
              variant={active === 1 ? "mapStateSelectedButton" : "mapStateButton"}
              onClick={() => setMapStateButton(en.creationPage.mapState.currentState, 1)}
              leftIcon={
                <HStack spacing="8px" marginTop="10px">
                  {active === 1 && <SelectedIcon w="27px" h="24px" />}
                </HStack>
              }
            >
              {en.creationPage.mapState.currentState}
            </Button>
            <Button
              variant={active === 2 ? "mapStateSelectedButton" : "mapStateButton"}
              onClick={() => setMapStateButton(en.creationPage.mapState.futureState, 2)}
              leftIcon={
                <HStack spacing="8px" marginTop="10px">
                  {active === 2 && <SelectedIcon w="27px" h="24px" />}
                </HStack>
              }
            >
              {en.creationPage.mapState.futureState}
            </Button>
          </ButtonGroup>
          {/* Let's start mapping! */}
          <OverviewSection
            header={en.creationPage.startMapping.header}
            body1={en.creationPage.startMapping.body}
          />
          <CreationAccordion />
          <Box sx={styles.buttonContainer}>
            <Button variant="outlineLarge" onClick={() => resetMappingToolCreation("openMapTool")}>
              {en.common.reset}
            </Button>
            <Button variant="yellowLarge" onClick={downloadModal.onOpen}>
              {en.common.done}
            </Button>
          </Box>
        </Box>
      </Box>
      <DownloadModal
        isOpen={downloadModal.isOpen}
        onClose={downloadModal.onClose}
        handleWarningModal={handleWarningModal}
        createMultiPagePDF={createMultiPagePDF}
        isDownloading={isDownloading}
      />
      <WarningModal
        isOpen={warningModalIsOpen}
        onClose={warningModalClose}
        createMultiPagePDF={createMultiPagePDF}
        isDownloading={isDownloading}
      />
    </>
  );
};

export default MappingToolCreation;

const styles = {
  headerContainer: {
    borderBottom: "2px solid",
    borderColor: "gray20",
    cursor: "pointer",
    display: "flex",
    height: "2.75rem",
    justifyContent: "space-between",
    margin: "20px 56px 26px 56px",
  },
  overviewContainer: {
    overflowY: "auto",
    overflowX: "hidden",
    h: "100%",
    w: "100%",
    mb: "32px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "0px 56px 32px 56px",
  },
  mapStateButtonContainer: {
    alignSelf: "stretch",
    display: "flex",
    justifyContent: "space-between",
    gap: "12px",
    margin: "0px 56px 32px 56px",
    paddingTop: "12px",
  },
};
