import React from "react";
import { Box, Center, Flex, Image, Text } from "@chakra-ui/react";
import en from "../../lib/en.json";

function NotOptimizedForMobile() {
  return (
    <Box height="100vh" width="100vw">
      <Center h="100%">
        <Flex direction="column" align="center">
          <Image
            src={require("../../assets/images/tkf_logo_hi_res.png")}
            htmlWidth="165px"
            htmlHeight="48px"
          />
          <Box sx={styles.textContainer}>
            <Text sx={styles.titleGray}>{en.landingPage.notOptimizedForMobile.title}</Text>
            <Text sx={styles.bodyGray}>{en.landingPage.notOptimizedForMobile.description}</Text>
          </Box>
        </Flex>
      </Center>
    </Box>
  );
}

export default NotOptimizedForMobile;

const styles = {
  textContainer: {
    alignItems: "center",
    background: "primaryYellow",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    marginTop: "30px",
    minHeight: "120px",
    p: "1.5rem 2rem",
  },
  titleGray: {
    color: "gray60",
    fontSize: "1.125rem",
    fontWeight: 700,
    paddingTop: "10px",
    textAlign: "center",
  },
  bodyGray: {
    color: "gray60",
    fontSize: "0.875rem",
    paddingBottom: "10px",
    width: "100%",
  },
};
