import React, { useContext, useState } from "react";
import {
  ContinuumOfCareDefaultIcon,
  ContinuumOfCareClickedIcon,
  ContinuumOfCareHoverIcon,
  ContinuumOfCareDefaultSmallIcon,
} from "../../components/Icons/Icons";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

type Props = {
  downloadModal: boolean;
};

function ContinuumOfCare({ downloadModal }: Props) {
  const { selectedSector, setSelectedSector, startCreation } = useContext(
    SectorContext,
  ) as UpdateDataContextType;
  const [isHover, setIsHover] = useState(false);

  const getSelectedSector = () => {
    if (startCreation) {
      setSelectedSector(null);
      return selectedSector;
    } else {
      setSelectedSector(1000);
      return selectedSector;
    }
  };

  const determineIconToRender = () => {
    if (downloadModal) {
      return <ContinuumOfCareDefaultSmallIcon />;
    } else if (isHover && selectedSector !== 1000) {
      return <ContinuumOfCareHoverIcon />;
    } else if (selectedSector === 1000) {
      return <ContinuumOfCareClickedIcon />;
    } else {
      return <ContinuumOfCareDefaultIcon />;
    }
  };

  let width, sizeOffset: number;
  if (downloadModal) {
    width = 60;
    sizeOffset = 60 / 2;
  } else {
    width = 164;
    sizeOffset = 164 / 2;
  }
  return (
    <foreignObject
      width={`${width}px`}
      height={`${width}px`}
      x={-sizeOffset}
      y={-sizeOffset}
      cx={0}
      cy={0}
      onClick={getSelectedSector}
      style={!downloadModal && !startCreation ? { cursor: "pointer" } : {}}
      onPointerMove={() => {
        if (!downloadModal && !startCreation && selectedSector !== 1000) {
          setIsHover(true);
        }
      }}
      onPointerOut={() => {
        if (!downloadModal && !startCreation) {
          setIsHover(false);
        }
      }}
    >
      {determineIconToRender()}
    </foreignObject>
  );
}

export default ContinuumOfCare;
