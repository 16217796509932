export type NoteState = {
  notes: string;
  editMode: boolean;
};

export type NoteAction = 
  | { type: 'SAVE_NOTE'; payload: string }
  | { type: 'EDIT_NOTE'; payload: string }
  | { type: 'SYNC_NOTE'; payload: string }
  | { type: 'REMOVE_NOTE' }
  | { type: 'CANCEL_EDIT' };

export const noteReducer = (state: NoteState, action: NoteAction): NoteState => {
  switch (action.type) {
    case 'SAVE_NOTE':
      return { ...state, notes: action.payload, editMode: false };
    case 'EDIT_NOTE':
      return { ...state, notes: action.payload, editMode: true };
    case 'SYNC_NOTE':
      return { ...state, notes: action.payload, editMode: false };
    case 'REMOVE_NOTE':
      return { ...state, notes: '', editMode: false };
    case 'CANCEL_EDIT':
      return { ...state, editMode: false };
    default:
      return state;
  }
};

