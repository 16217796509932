const Button = {
  baseStyle: {
    border: "2px solid",
    borderRadius: "none",
    fontSize: ["0.5rem", "0.625rem", "0.75rem"],
    fontWeight: 500,
    height: "2.25rem",
    letterSpacing: "0.5px",
    lineHeight: "0.75rem",
    padding: "0.75rem 1.75rem",
  },
  variants: {
    yellow: {
      backgroundColor: "primaryYellow",
      borderColor: "primaryYellow",
    },
    yellowLarge: {
      backgroundColor: "primaryYellow",
      borderColor: "primaryYellow",
      gap: "32px",
      height: "48px",
      width: "45%",
    },
    yellowXL: {
      backgroundColor: "primaryYellow",
      borderColor: "primaryYellow",
      gap: "32px",
      height: "48px",
      padding: "20px",
      width: "100%",
    },
    outline: {
      backgroundColor: "white",
      borderColor: "black",
    },
    outlineLarge: {
      border: "1px solid",
      backgroundColor: "white",
      borderColor: "gray90",
      gap: "32px",
      height: "48px",
      width: "45%",
    },
    outlineXL: {
      border: "1px solid",
      backgroundColor: "white",
      borderColor: "gray90",
      gap: "32px",
      height: "48px",
      padding: "20px",
      width: "100%",
    },
    plainText: {
      border: "none",
      fontSize: ["0.75rem", "0.875rem", "1rem"],
      height: "120px",
      lineHeight: "1rem",
      color: "gray60",
      paddingInline: "0px",
    },
    hover: {
      cursor: "pointer",
    },
    roleTags: {
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "gray200",
      borderRadius: "8px",
      height: "100%",
      paddingInline: "0.5rem",
      marginRight: "0.25rem",
      _hover: {
        borderColor: "black",
      },
    },
    mapStateButton: {
      alignItems: "start",
      backgroundColor: "white",
      border: "1px solid",
      borderColor: "gray200",
      borderRadius: "8px",
      justifyContent: "flex-start",
      pb: "2.25rem",
      pt: "1.5rem",
      width: "100%",
    },
    mapStateSelectedButton: {
      alignItems: "start",
      backgroundColor: "white",
      border: "1px solid black",
      borderRadius: "8px",
      fontWeight: 700,
      justifyContent: "flex-start",
      pb: "2.25rem",
      pt: "1.5rem",
      width: "100%",
    },
  },
};

export default Button;
