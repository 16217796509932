import React, { Dispatch, SetStateAction } from "react";
import { Textarea } from "@chakra-ui/react";

export type Props = {
  placeholder: string;
  setDefineIssue: Dispatch<SetStateAction<string>>;
  defineIssue: string;
};

const TextBox = ({ placeholder, setDefineIssue, defineIssue }: Props) => {
  return (
    <Textarea
      sx={styles}
      onChange={(e) => setDefineIssue(e.target.value)}
      placeholder={placeholder}
      value={defineIssue}
      maxLength={250}
    />
  );
};

export default TextBox;

const styles = {
  textAlign: "left",
  fontSize: "14px",
  fontWeight: 400,
  margin: "0px 56px 32px 56px",
  resize: "none",
  width: "85%",
};
