import React, { FC, useContext } from "react";
import { Button, IconProps, HStack, Text } from "@chakra-ui/react";
import { SelectedIcon } from "../Icons/Icons";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

export type Props = {
  role: string;
  icon: FC<IconProps>;
  id: number;
};

const RoleTagsItem = ({ id, role, icon: IconComponent }: Props) => {
  const { data, updateRoleSelected, updateStrokeWidth } = useContext(
    SectorContext,
  ) as UpdateDataContextType;

  const outerItem = data.find((item) => item.id === Math.floor(id));
  const sector = outerItem ? outerItem.sectors.find((s) => s.id === id) : null;
  const isClicked = sector ? sector.rolesSelected.includes(role) : false;

  const updateRoleTag = () => {
    updateRoleSelected(id, role);
    updateStrokeWidth();
  };

  return (
    <Button
      sx={isClicked ? styles.roleTagsSelectedButton : styles.roleTagsButton}
      onClick={updateRoleTag}
      leftIcon={
        <HStack spacing="8px">
          {isClicked && <SelectedIcon w="27px" h="24px" />}
          <IconComponent w="20px" h="20px" />
        </HStack>
      }
    >
      <Text variant="roleTags">{role}</Text>
    </Button>
  );
};

export default RoleTagsItem;

const styles = {
  roleTagsButton: {
    height: "2rem",
    mb: "0.25rem",
  },
  roleTagsSelectedButton: {
    height: "2rem",
    border: "1px solid black",
    fontWeight: 700,
    mb: "0.25rem",
  },
};
