import React from "react";
import { useMediaQuery } from "@chakra-ui/react";
import Home from "../pages/Home/Home";
import NotOptimizedForMobile from "../components/NotOptimizedForMobile/NotOptimizedForMobile";
import { SectorContextProvider } from "../contexts/SectorContext";

function App() {
  const [isMobile] = useMediaQuery("(max-width: 915px)");

  return (
    <SectorContextProvider>
      <div className="App">{isMobile ? <NotOptimizedForMobile /> : <Home />}</div>
    </SectorContextProvider>
  );
}

export default App;
