import React from "react";
import { Flex, Link, Text, VStack } from "@chakra-ui/react";
import en from "../../lib/en.json";

export type KeyIssueSource = {
  source: string;
  sourceLink: string;
};

export type Props = {
  descriptionText: string;
  sources: KeyIssueSource[];
};

const KeyIssueAreaItem = ({ descriptionText, sources }: Props) => {
  return (
    <VStack textAlign="left" mt="24px">
      <Text variant="paragraph1">{descriptionText}</Text>
      <Flex w="100%" flexWrap={"wrap"}>
        <Text variant="paragraph4" mr="4px">{en.common.source}: </Text>
        {sources.map((source, i) => {
          const isLast = i === sources.length - 1;
          return (
            <Link
              href={source.sourceLink}
              isExternal
              key={`${source.source}-${i}`}
              textDecorationLine={"underline"}
            >
              {sources.length > 1 && !isLast ? (
                <Text variant="sourceLink">
                  {source.source}
                  {", "}
                </Text>
              ) : (
                <Text variant="sourceLink">{source.source}</Text>
              )}
            </Link>
          );
        })}
      </Flex>
    </VStack>
  );
};

export default KeyIssueAreaItem;
