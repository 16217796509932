import React, { useContext } from "react";
import { PieProps, ProvidedProps, PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { animated, useTransition, to } from "@react-spring/web";
import { Center, Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { arcIconRoleMapping, arcIconRoleMappingSmall } from "../../lib/constants";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import {
  calculateXAndYOffset,
  calculateXAndYForStickers,
  calculateXAndYWithRadius,
  determinePaddingSizeAndSide,
} from "../../utils/helpers";
import { InnerSectorData } from "../../utils/data";
import { RiskFactorsArrowIcon, RiskFactorsSmallArrowIcon } from "../../components/Icons/Icons";
function fromLeaveTransition<T>({ endAngle }: PieArcDatum<T>) {
  // enter from 360° if end angle is > 180°
  return {
    startAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    endAngle: endAngle > Math.PI ? 2 * Math.PI : 0,
    opacity: 0,
  };
}
function enterUpdateTransition<T>({ startAngle, endAngle }: PieArcDatum<T>) {
  return {
    startAngle,
    endAngle,
    opacity: 1,
  };
}

type AnimatedPieProps<Datum> = ProvidedProps<Datum> &
  PieProps<Datum> & {
    animate?: boolean;
    getKey: (d: PieArcDatum<Datum>) => string;
    onClickDatum?: (d: PieArcDatum<Datum>) => void;
    delay?: number;
    downloadModal: boolean;
    radius: number;
    selectedSector: number | null;
    setSelectedSector: (id: number) => void;
    width: number;
  };
type AnimatedStyles = { startAngle: number; endAngle: number; opacity: number };

function AnimatedPieSlice<Datum>({
  animate,
  arcs,
  downloadModal,
  path,
  getKey,
  radius,
  selectedSector,
  setSelectedSector,
  width,
}: AnimatedPieProps<Datum>) {
  type ExtendedDatum = Datum & InnerSectorData;
  const { startCreation } = useContext(SectorContext) as UpdateDataContextType;
  const ninetyDegreesInRadians = 1.5708;

  const transitions = useTransition<PieArcDatum<Datum>, AnimatedStyles>(arcs, {
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    keys: getKey,
  });
  console.log(width);

  function calculateSubArcLengthAndStartingPoint(
    arc: PieArcDatum<ExtendedDatum>,
    stickerQuantity: number,
  ) {
    const arcLength = arc.endAngle - arc.startAngle;
    // Padding on either side of the arc before rendering stickers
    const arcLengthPadding = 0.15 * arcLength;
    // Length of arc to render stickers on
    const subArcLength = (stickerQuantity - 1) * arcLengthPadding;
    const startingPoint = (arcLength - subArcLength) / 2;
    return { subArcLength, startingPoint };
  }

  function calculateXAndYForLabelText(
    arc: PieArcDatum<ExtendedDatum>,
    height: number,
    width: number,
    radius: number,
  ) {
    const middleOfArc = arc.startAngle + (arc.endAngle - arc.startAngle) / 2;
    // We need the ninety degree offset when finding the x and y coordinates
    const arcStartingPointInRadians = middleOfArc - ninetyDegreesInRadians;
    // Find base coordinates
    const { baseXCoordinate, baseYCoordinate } = calculateXAndYWithRadius(
      arcStartingPointInRadians,
      radius,
    );
    // Need to account for size of the text box, and ensure top left of text box isn't placed at x, y
    // Instead, need to center the text box placement on x, y
    const { x, y } = calculateXAndYOffset(height, width + 1, baseXCoordinate, baseYCoordinate);
    return { x, y };
  }

  const determineWhichStickersToRender = (arc: PieArcDatum<ExtendedDatum>) => {
    if (arc.data.rolesSelected) {
      const stickers = arc.data.rolesSelected;
      const { subArcLength, startingPoint } = calculateSubArcLengthAndStartingPoint(
        arc,
        stickers.length,
      );
      // We need the ninety degree offset when finding the x and y coordinates
      const arcStartingPointInRadians = startingPoint + arc.startAngle - ninetyDegreesInRadians;

      return stickers.map((role: string, i: number) => {
        let icon;
        let size: number;
        let border: string;
        if (width > 650) {
          border = "2px solid";
          icon = arcIconRoleMapping[role];
          size = 20;
        } else {
          border = "1px solid";
          icon = arcIconRoleMappingSmall[role];
          size = 10;
        }
        if (i === 0) {
          const { x, y } = calculateXAndYForStickers(arcStartingPointInRadians, 0, radius, size);
          return (
            <foreignObject x={x} y={y} width={size} height={size} key={`${arc.data.title}-${i}`}>
              <Center>
                <Icon as={icon} sx={styles.roleIcon} border={border} />
              </Center>
            </foreignObject>
          );
        } else {
          const numOfStickers = stickers.length;
          // Update the amount of padding based upon how many stickers need to render
          const padding = subArcLength / (numOfStickers - 1);
          const updatedPadding = padding * i;
          const { x, y } = calculateXAndYForStickers(
            arcStartingPointInRadians,
            updatedPadding,
            radius,
            size,
          );
          return (
            <foreignObject x={x} y={y} width={size} height={size} key={`${arc.data.title}-${i}`}>
              <Center>
                <Icon as={icon} sx={styles.roleIcon} border={border} />
              </Center>
            </foreignObject>
          );
        }
      });
    }
  };

  /* eslint-disable-next-line */
  function renderTextLabelForSlices(arc: PieArcDatum<ExtendedDatum>, props: any) {
    let labelWidth: number;
    if (width < 650) {
      labelWidth = arc.data.labelWidth / 1.9;
    } else {
      labelWidth = arc.data.labelWidth;
    }
    const height = 45;
    const modifiedRadius = radius * 0.81;
    const { x, y } = calculateXAndYForLabelText(arc, height, labelWidth, modifiedRadius);

    const updateTextLabelForSlices = () => {
      switch (true) {
        case !downloadModal && width >= 935:
          return "0.75rem";
        case !downloadModal && width < 650:
          return "0.375rem";
        case !downloadModal && (width > 650 || width < 935):
          return "0.5rem";
        case downloadModal && width < 650:
          return "0.25rem";
      }
    };

    return (
      <animated.g style={{ opacity: props.opacity }}>
        <foreignObject
          height={48}
          width={labelWidth + 1}
          x={x}
          y={y}
          onClick={() => {
            if (downloadModal || startCreation) {
              return;
            } else if (Math.floor(arc.data.id) !== 0) {
              setSelectedSector(arc.data.id);
            }
          }}
          style={
            downloadModal || startCreation
              ? { ...determinePaddingSizeAndSide(arc.data.id, width) }
              : { ...determinePaddingSizeAndSide(arc.data.id, width), cursor: "pointer" }
          }
        >
          <Flex sx={styles.textBox} align="center">
            <Text
              color={arc.data.id === selectedSector ? "#FFFFFF" : "#000000"}
              fontSize={updateTextLabelForSlices()}
              letterSpacing="0.072px"
              align={arc.data.id > 2.4 ? "left" : "right"}
            >
              {getKey(arc).toUpperCase()}
            </Text>
          </Flex>
        </foreignObject>
      </animated.g>
    );
  }

  /* eslint-disable-next-line */
  function renderRiskText(arc: PieArcDatum<ExtendedDatum>, props: any) {
    let height, labelWidth, modifiedRadius: number;
    if (width < 650) {
      labelWidth = arc.data.labelWidth / 2.25;
      height = 135 / 2.25;
      modifiedRadius = radius * 0.75;
    } else if (width > 935) {
      labelWidth = arc.data.labelWidth;
      height = 135;
      modifiedRadius = radius * 0.7;
    } else {
      labelWidth = arc.data.labelWidth / 1.35;
      height = 135 / 1.25;
      modifiedRadius = radius * 0.7;
    }

    const updateTextLabelForRisk = () => {
      switch (true) {
        case width >= 935:
          return "1rem";
        case width < 650:
          return "0.438rem";
        case width > 650 || width < 935:
          return "0.65rem";
      }
    };
    const { x, y } = calculateXAndYForLabelText(arc, height, labelWidth, modifiedRadius);
    return (
      <animated.g style={{ opacity: props.opacity }}>
        <foreignObject height={height} width={labelWidth + 1} x={x} y={y}>
          <VStack height="100%" width="100%">
            <Text
              sx={styles.riskText}
              lineHeight="120%"
              fontWeight={700}
              fontSize={updateTextLabelForRisk()}
              textAlign="center"
            >
              {getKey(arc)}
            </Text>
            <Text
              sx={styles.riskText}
              lineHeight="140%"
              fontWeight={400}
              fontSize={updateTextLabelForRisk()}
              textAlign="center"
            >
              {arc.data.subtitle}
            </Text>
          </VStack>
        </foreignObject>
      </animated.g>
    );
  }

  function renderRiskArrow(arc: PieArcDatum<ExtendedDatum>) {
    let [centroidX, centroidY] = path.centroid(arc);
    let arrowWidth, arrowHeight, arrowWidthOffset: number;
    let icon;
    if (width >= 935) {
      arrowWidth = 24;
      arrowHeight = 68;
      arrowWidthOffset = 24 / 2;
      icon = RiskFactorsArrowIcon;
    } else if (width < 650) {
      arrowWidth = 16;
      arrowHeight = 38;
      arrowWidthOffset = 16 / 2;
      icon = RiskFactorsSmallArrowIcon;
    } else {
      arrowWidth = 16;
      arrowHeight = 38;
      arrowWidthOffset = 16 / 2;
      icon = RiskFactorsSmallArrowIcon;
      centroidY = centroidY - 10;
    }

    centroidX = centroidX - arrowWidthOffset;
    return (
      <foreignObject
        width={arrowWidth}
        height={arrowHeight}
        x={centroidX}
        y={centroidY}
        cx={0}
        cy={0}
      >
        <Icon as={icon} w="100%" h="100%" />
      </foreignObject>
    );
  }

  /* eslint-disable-next-line */
  return transitions((props, arc: PieArcDatum<any>, { key }) => {
    const hasSpaceForLabel = arc.endAngle - arc.startAngle >= 0.1;

    return (
      <g key={key}>
        <animated.path
          // compute interpolated path d attribute from intermediate angle values
          d={to([props.startAngle, props.endAngle], (startAngle, endAngle) =>
            path({
              ...arc,
              startAngle,
              endAngle,
            }),
          )}
          fill={arc.data.id === selectedSector ? arc.data.selectedColor : arc.data.baseColor}
          stroke={arc.data.id === selectedSector ? "#FFFFFF" : arc.data.selectedColor}
          strokeWidth={
            arc.data.rolesSelected.length > 0 || arc.data.id === selectedSector ? "1px" : "0px"
          }
          filter={
            arc.data.rolesSelected.length > 0
              ? `drop-shadow(0px 0px 12px ${arc.data.shadowRGBA})`
              : "none"
          }
          onClick={() => {
            if (downloadModal || startCreation) {
              return;
            } else if (Math.floor(arc.data.id) !== 0) {
              setSelectedSector(arc.data.id);
            }
          }}
          onPointerMove={(event) => {
            if (!downloadModal && !startCreation) {
              event.currentTarget.style.filter = `drop-shadow(0px 0px 16px ${arc.data.shadowRGBA})`;
            }
          }}
          onPointerOut={(event) => {
            if (!downloadModal && !startCreation) {
              event.currentTarget.style.filter = "none";
            }
          }}
          style={!downloadModal && !startCreation ? { ...styles.pieSlice } : {}}
        />
        {hasSpaceForLabel && arc.data.id === 0.1 && renderRiskArrow(arc)}
        {hasSpaceForLabel && arc.data.id === 0.1 && renderRiskText(arc, props)}
        {hasSpaceForLabel && arc.data.id > 0.1 && renderTextLabelForSlices(arc, props)}
        {determineWhichStickersToRender(arc)}
      </g>
    );
  });
}

const styles = {
  pieSlice: {
    cursor: "pointer",
  },
  roleIcon: {
    borderColor: "white",
    borderRadius: "100%",
    height: "100%",
    width: "100%",
  },
  riskText: {
    color: "#000",
    letterSpacing: "0.072px",
  },
  textBox: {
    height: "100%",
    justifyContent: "center",
    width: "100%",
  },
};

export default AnimatedPieSlice;
