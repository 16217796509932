import React from "react";
import { ComponentWithAs, HStack, Icon, IconProps, Text, VStack } from "@chakra-ui/react";

export type Props = {
  headerText: string;
  descriptionText: string;
  icon: ComponentWithAs<"svg", IconProps>;
};

const RoleItem = ({ headerText, descriptionText, icon }: Props) => {
  return (
    <VStack sx={styles.container}>
      <HStack w="100%">
        <Icon as={icon} />
        <Text variant="header3">{headerText}</Text>
      </HStack>
      <Text as="i" w="100%" variant="paragraph2">
        {descriptionText}
      </Text>
    </VStack>
  );
};

export default RoleItem;

const styles = {
  container: {
    as: "span",
    borderBottom: "1px solid",
    borderColor: "gray10",
    flex: 2,
    p: "16px 0px",
    textAlign: "left",
  },
};
