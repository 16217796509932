import React, { useContext } from "react";
import { Box, Button, Text, Flex } from "@chakra-ui/react";
import en from "../../lib/en.json";
import { DispatchType } from "./MappingNotes";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

export type Props = {
  dispatch: DispatchType;
  notes: string;
  id: number;
  pdfOutput: boolean;
};

const SavedNoteBox = ({ dispatch, notes, id, pdfOutput }: Props) => {
  const { updateNotes } = useContext(SectorContext) as UpdateDataContextType;

  const removeNote = () => {
    dispatch({ type: "REMOVE_NOTE" });
    updateNotes(id, "");
  };

  return (
    <Box sx={styles.savedTextBox}>
      <Text variant={"notes"} sx={pdfOutput ? styles.savedNoteTextPDF : styles.savedNoteText}>
        {notes}
      </Text>
      {!pdfOutput && (
        <Flex gap="0.5rem">
          <Button
            variant="yellow"
            sx={styles.editButton}
            onClick={() => dispatch({ type: "EDIT_NOTE", payload: notes })}
          >
            {en.common.edit}
          </Button>
          <Button variant="outline" sx={styles.removeButton} onClick={removeNote}>
            {en.common.remove}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default SavedNoteBox;

const styles = {
  savedTextBox: {
    width: "100%",
    padding: "0.5rem 0.75rem",
    border: "1px solid",
    borderColor: "gray30",
    borderRadius: "0.5rem",
  },
  savedNoteText: {
    width: "100%",
    textAlign: "left",
    color: "gray80",
    lineHeight: "140%",
    fontStyle: "italic",
    mb: "1.25rem",
  },
  savedNoteTextPDF: {
    width: "100%",
    textAlign: "left",
    color: "gray80",
    lineHeight: "140%",
    fontStyle: "italic",
  },
  editButton: {
    width: "50%",
    borderRadius: "0.5rem",
  },
  removeButton: {
    width: "50%",
    borderRadius: "0.5rem",
  },
};
