import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";
import Pie, { PieProps } from "@visx/shape/lib/shapes/Pie";
import AnimatedPieSlice from "../AnimatedPieSlice/AnimatedPieSlice";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import { Data, InnerSectorData } from "../../utils/data";

type PieChartProps<Datum> = PieProps<Datum> & {
  radius: number;
  startAngle?: number;
  data: Data[] | InnerSectorData[];
  downloadModal: boolean;
  animate?: boolean;
  outerRadius: number;
  width: number;
};

//Innermost sectors
function PieChart<Datum extends Data | InnerSectorData>({
  outerRadius,
  startAngle,
  data,
  downloadModal,
  animate = true,
  width,
}: PieChartProps<Datum>) {
  const { selectedSector, setSelectedSector, startCreation } = useContext(
    SectorContext,
  ) as UpdateDataContextType;

  const getSelectedSector = () => {
    if (startCreation) {
      setSelectedSector(null);
      return selectedSector;
    } else {
      return selectedSector;
    }
  };

  useEffect(() => {
    getSelectedSector();
  }, [startCreation]);

  return (
    <Pie
      data={data}
      pieValue={(d) => d.value}
      outerRadius={outerRadius}
      startAngle={startAngle}
      pieSort={(a, b) => a.id - b.id}
    >
      {(pie) => {
        return (
          <AnimatedPieSlice
            animate={animate}
            downloadModal={downloadModal}
            getKey={({ data: { title } }) => title}
            radius={outerRadius}
            selectedSector={selectedSector}
            setSelectedSector={setSelectedSector}
            width={width}
            {...pie}
          />
        );
      }}
    </Pie>
  );
}

PieChart.propTypes = {
  radius: PropTypes.number,
  startAngle: PropTypes.number,
  data: PropTypes.arrayOf(PropTypes.object),
  animate: PropTypes.bool,
  outerRadius: PropTypes.number,
};

export default PieChart;
