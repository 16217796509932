import en from "../lib/en.json";
import theme from "../theme/theme";

export interface InnerSectorData {
  all: number;
  baseColor: string;
  id: number;
  labelWidth: number;
  notes: string;
  rolesSelected: string[];
  selectedColor: string;
  shadowRGBA: string;
  stakeholders: string[];
  subtitle?: string;
  title: string;
  value: number;
}

export interface Data {
  title: string;
  value: number;
  all: number;
  baseColor: string;
  labelColor: string;
  labelWidth: number;
  strokeWidth: string;
  id: number;
  sectors: InnerSectorData[];
  rolesSelected?: string[];
}

export const data: Data[] = [
  {
    title: "",
    value: 75,
    all: 600,
    baseColor: theme.colors.white,
    labelColor: theme.colors.white,
    labelWidth: 0,
    strokeWidth: "0px",
    id: 0,
    sectors: [
      {
        title: en.systemsMapWheel.riskAndProtectiveFactors,
        subtitle: en.systemsMapWheel.egPovertyRacismETC,
        value: 75,
        all: 600,
        baseColor: theme.colors.white,
        id: 0.1,
        labelWidth: 150,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.white,
        shadowRGBA: "rgba(0, 0, 0, 0)",
        stakeholders: [],
      },
    ],
  },
  {
    title: `${en.systemsMapWheel.sectors.healthSector.title}`,
    value: 105,
    all: 600,
    baseColor: theme.colors.darkPurple,
    labelColor: theme.colors.darkPurple,
    labelWidth: 75,
    strokeWidth: "1px",
    id: 1,
    sectors: [
      {
        title: `${en.systemsMapWheel.sectors.healthSector.subSectors.healthProviders}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightPurple25,
        id: 1.1,
        labelWidth: 70,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkPurple,
        shadowRGBA: "rgba(76, 3, 137, 0.42)",
        stakeholders: en.landingPage.sectorDetails.healthProviders.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.healthSector.subSectors.healthInsurance}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightPurple50,
        id: 1.2,
        labelWidth: 72,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkPurple,
        shadowRGBA: "rgba(76, 3, 137, 0.42)",
        stakeholders: en.landingPage.sectorDetails.healthInsurance.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.healthSector.subSectors.medDevicesPharma}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightPurple75,
        id: 1.3,
        labelWidth: 85,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkPurple,
        shadowRGBA: "rgba(76, 3, 137, 0.42)",
        stakeholders: en.landingPage.sectorDetails.medDevicesPharma.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.healthSector.subSectors.research}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightPurple,
        id: 1.4,
        labelWidth: 65,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkPurple,
        shadowRGBA: "rgba(76, 3, 137, 0.42)",
        stakeholders: en.landingPage.sectorDetails.research.stakeholders,
      },
    ],
  },
  {
    title: `${en.systemsMapWheel.sectors.humanServices.title}`,
    value: 105,
    all: 600,
    baseColor: theme.colors.darkOrange,
    labelColor: theme.colors.darkOrangeLabel,
    labelWidth: 70,
    strokeWidth: "1px",
    id: 2,
    sectors: [
      {
        title: `${en.systemsMapWheel.sectors.humanServices.subSectors.education}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightOrange25,
        id: 2.1,
        labelWidth: 71,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkOrange,
        shadowRGBA: "rgba(197, 117, 9, 0.42)",
        stakeholders: en.landingPage.sectorDetails.education.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.humanServices.subSectors.communitySupport}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightOrange50,
        id: 2.2,
        labelWidth: 77,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkOrange,
        shadowRGBA: "rgba(197, 117, 9, 0.42)",
        stakeholders: en.landingPage.sectorDetails.communitySupport.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.humanServices.subSectors.socialServices}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightOrange75,
        id: 2.3,
        labelWidth: 59,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkOrange,
        shadowRGBA: "rgba(197, 117, 9, 0.42)",
        stakeholders: en.landingPage.sectorDetails.socialServices.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.humanServices.subSectors.criminalLegal}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightOrange,
        id: 2.4,
        labelWidth: 58,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkOrange,
        shadowRGBA: "rgba(197, 117, 9, 0.42)",
        stakeholders: en.landingPage.sectorDetails.criminalLegal.stakeholders,
      },
    ],
  },
  {
    title: `${en.systemsMapWheel.sectors.publicSector.title}`,
    value: 105,
    all: 600,
    baseColor: theme.colors.darkGreen,
    labelColor: theme.colors.darkGreenLabel,
    labelWidth: 100,
    strokeWidth: "1px",
    id: 3,
    sectors: [
      {
        title: `${en.systemsMapWheel.sectors.publicSector.subSectors.legislator}`,
        value: 26.25,
        all: 105,
        baseColor: theme.colors.lightGreen25,
        id: 3.1,
        labelWidth: 81,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkGreen,
        shadowRGBA: "rgba(65, 155, 16, 0.42)",
        stakeholders: en.landingPage.sectorDetails.legislator.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.publicSector.subSectors.federalGov}`,
        value: 26.25,
        all: 105,
        baseColor: theme.colors.lightGreen50,
        id: 3.2,
        labelWidth: 50,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkGreen,
        shadowRGBA: "rgba(65, 155, 16, 0.42)",
        stakeholders: en.landingPage.sectorDetails.federalGov.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.publicSector.subSectors.stateTribalGov}`,
        value: 26.25,
        all: 105,
        baseColor: theme.colors.lightGreen75,
        id: 3.3,
        labelWidth: 50,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkGreen,
        shadowRGBA: "rgba(65, 155, 16, 0.42)",
        stakeholders: en.landingPage.sectorDetails.stateTribalGov.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.publicSector.subSectors.localGov}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightGreen,
        id: 3.4,
        labelWidth: 47,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkGreen,
        shadowRGBA: "rgba(65, 155, 16, 0.42)",
        stakeholders: en.landingPage.sectorDetails.localGov.stakeholders,
      },
    ],
  },
  {
    title: `${en.systemsMapWheel.sectors.privateSector.title}`,
    value: 105,
    all: 600,
    baseColor: theme.colors.darkTeal,
    labelColor: theme.colors.darkTealLabel,
    labelWidth: 70,
    strokeWidth: "1px",
    id: 4,
    sectors: [
      {
        title: `${en.systemsMapWheel.sectors.privateSector.subSectors.privateSectorFunders}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightTeal25,
        id: 4.1,
        labelWidth: 59,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkTeal,
        shadowRGBA: "rgba(34, 173, 167, 0.42)",
        stakeholders: en.landingPage.sectorDetails.privateSectorFunders.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.privateSector.subSectors.technology}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightTeal50,
        id: 4.2,
        labelWidth: 84,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkTeal,
        shadowRGBA: "rgba(34, 173, 167, 0.42)",
        stakeholders: en.landingPage.sectorDetails.technology.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.privateSector.subSectors.employers}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightTeal75,
        id: 4.3,
        labelWidth: 72,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkTeal,
        shadowRGBA: "rgba(34, 173, 167, 0.42)",
        stakeholders: en.landingPage.sectorDetails.employers.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.privateSector.subSectors.mediaAndComm}`,
        value: 26.25,
        all: 600,
        baseColor: theme.colors.lightTeal,
        id: 4.4,
        labelWidth: 55,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkTeal,
        shadowRGBA: "rgba(34, 173, 167, 0.42)",
        stakeholders: en.landingPage.sectorDetails.mediaAndComm.stakeholders,
      },
    ],
  },
  {
    title: `${en.systemsMapWheel.sectors.civilSociety.title}`,
    value: 105,
    all: 600,
    baseColor: theme.colors.darkBlue,
    labelColor: theme.colors.darkBlueLabel,
    labelWidth: 70,
    strokeWidth: "1px",
    id: 5,
    sectors: [
      {
        title: `${en.systemsMapWheel.sectors.civilSociety.subSectors.nonGovOrgs}`,
        value: 35,
        all: 600,
        baseColor: theme.colors.lightBlue50,
        id: 5.1,
        labelWidth: 84,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkBlue,
        shadowRGBA: "rgba(0, 132, 255, 0.42)",
        stakeholders: en.landingPage.sectorDetails.nonGovOrgs.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.civilSociety.subSectors.communitiesIndividuals}`,
        value: 35,
        all: 600,
        baseColor: theme.colors.lightBlue75,
        id: 5.2,
        labelWidth: 85,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkBlue,
        shadowRGBA: "rgba(0, 132, 255, 0.42)",
        stakeholders: en.landingPage.sectorDetails.communitiesIndividuals.stakeholders,
      },
      {
        title: `${en.systemsMapWheel.sectors.civilSociety.subSectors.peopleWLivedExp}`,
        value: 35,
        all: 600,
        baseColor: theme.colors.lightBlue,
        id: 5.3,
        labelWidth: 78,
        rolesSelected: [],
        notes: "",
        selectedColor: theme.colors.darkBlue,
        shadowRGBA: "rgba(0, 132, 255, 0.42)",
        stakeholders: en.landingPage.sectorDetails.peopleWLivedExp.stakeholders,
      },
    ],
  },
];
