export function calculateXAndYWithRadius(startingPoint: number, radius: number, padding?: number) {
  let num: number;
  if (padding) {
    num = startingPoint + padding;
  } else {
    num = startingPoint;
  }
  const baseXCoordinate = radius * Math.cos(num);
  const baseYCoordinate = radius * Math.sin(num);
  return { baseXCoordinate, baseYCoordinate };
}

export function calculateXAndYOffset(
  height: number,
  width: number,
  baseXCoordinate: number,
  baseYCoordinate: number,
) {
  const heightOffset = height / 2;
  const widthOffset = width / 2;
  // Update the coordinates with the Icon size offset
  const x = baseXCoordinate - widthOffset;
  const y = baseYCoordinate - heightOffset;
  return { x, y };
}

export function calculateXAndYForStickers(
  arcStartingPointInRadians: number,
  padding: number,
  radius: number,
  size: number,
) {
  // Find base coordinates
  const { baseXCoordinate, baseYCoordinate } = calculateXAndYWithRadius(
    arcStartingPointInRadians,
    radius,
    padding,
  );
  // Need to account for size of sticker, and ensure top left of sticker isn't placed at x, y
  // Instead, need to center the sticker placement on x, y
  const { x, y } = calculateXAndYOffset(size, size, baseXCoordinate, baseYCoordinate);
  return { x, y };
}

export function determinePaddingSizeAndSide(width: number, id: number) {
  if (width < 650) {
    if (id > 2.4) {
      return { paddingLeft: "0px 0.5px" };
    } else {
      return { paddingRight: "0px 0.5px" };
    }
  } else {
    if (id > 2.4) {
      return { paddingLeft: "0px 2px" };
    } else {
      return { paddingRight: "0px 2px" };
    }
  }
}
