import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import en from "../../lib/en.json";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  createMultiPagePDF: () => Promise<void>;
  isDownloading: boolean;
};

const WarningModal = ({ isOpen, onClose, createMultiPagePDF, isDownloading }: Props) => {
  return (
    <Box>
      <Modal blockScrollOnMount={true} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={styles.modalContainer}>
          <Text variant="upperCaseHeader">{en.common.warning}</Text>
          <ModalHeader padding="0">
            <Text variant="header1">{en.modal.warningTitle}</Text>
            <Text variant="paragraph3">{en.modal.warningDescription}</Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalFooter sx={styles.modalFooter}>
            <Button onClick={createMultiPagePDF} variant="yellowXL" isLoading={isDownloading} loadingText={en.common.downloading}>
              {en.modal.downloadButton}
            </Button>
            <Button onClick={onClose} variant="outlineXL">
              {en.modal.proceed}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default WarningModal;

const styles = {
  modalContainer: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    minHeight: "362px",
    padding: "48px",
    minWidth: "720px",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    width: "100%",
  },
};
