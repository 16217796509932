import React, { useContext, useState } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import KeyIssueAreaItem, { Props as KeyIssueAreaItemProps } from "./KeyIssueAreaItem";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import { ehrRoles as data } from "../../utils/ehrRoles";

export type Props = {
  headerText: string;
  descriptionText: string;
  keyIssueAreaItems: KeyIssueAreaItemProps[];
};

const InspirationAccordionItem = ({ headerText, descriptionText, keyIssueAreaItems }: Props) => {
  const {
    updateRoleSelected,
    resetMap,
    updateStrokeWidth,
    resetStrokeWidth
  } = useContext(SectorContext) as UpdateDataContextType;

  const [isClicked, setIsClicked] = useState(false);

  const updateRoleTags = () => {
    setIsClicked(!isClicked);

    if (!isClicked) {
      data.forEach((donut) => {
        donut.sectors.forEach((role) => {
          role.rolesSelected.forEach((roleSelected) => {
            updateRoleSelected(role.id, roleSelected);
            updateStrokeWidth();
          });
        });
      });
    } else if (isClicked) {
      resetMap();
      resetStrokeWidth();
    }
  }

  return (
    <AccordionItem>
      <h2>
        <AccordionButton minHeight="52px" onClick={updateRoleTags}>
          <Box as="span" flex="1" textAlign="left">
            <Text sx={styles.headerText}>{headerText}</Text>
          </Box>
          <AccordionIcon />
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Text mb="24px">{descriptionText}</Text>
        <UnorderedList>
          {keyIssueAreaItems.map((issue) => {
            return (
              <ListItem key={issue.descriptionText}>
                <KeyIssueAreaItem
                  descriptionText={issue.descriptionText}
                  sources={issue.sources}
                  key={issue.descriptionText}
                />
              </ListItem>
            );
          })}
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  );
};

export default InspirationAccordionItem;

const styles = {
  headerText: {
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "100%",
    letterSpacing: 0.5,
  },
};
