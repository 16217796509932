import React, { useContext } from "react";
import { Box, Button, Text } from "@chakra-ui/react";
import { saveAs } from "file-saver";
import { InspirationAccordion, Tutorial } from "../index";
import { OverviewSection, TabAnchorLinkNavigation } from "../../design-system/index";
import { tabNavigationLinks } from "../../lib/constants";
import en from "../../lib/en.json";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

const MappingToolOverview = () => {
  const { setStartCreationActive, resetMap, resetStrokeWidth } = useContext(
    SectorContext,
  ) as UpdateDataContextType;

  const downloadPDFFiles = () => {
    // local path to your PDF files
    const pdfFile1Path = "/system-mapping-toolkit.pdf";

    saveAs(pdfFile1Path, "SystemsMapToolkit.pdf");
  };

  const startMapping = () => {
    setStartCreationActive(true);
    resetMap();
    resetStrokeWidth();
  };

  return (
    <Box h="90%">
      <TabAnchorLinkNavigation navLinks={tabNavigationLinks} />
      <Box sx={styles.overviewContainer}>
        <Box sx={styles.titleContainer}>
          {/* Title  */}
          <Text variant="header0Bold">{en.landingPage.title}</Text>
          <Text variant="header0">{en.landingPage.subtitle}</Text>
        </Box>
        {/* About */}
        <OverviewSection
          anchorTag={en.landingPage.aboutThisTool.anchorTag}
          header={en.landingPage.aboutThisTool.header}
          body1={en.landingPage.aboutThisTool.body}
        />
        {/* How to use */}
        <Tutorial />
        {/* Need Inspiration? */}
        <OverviewSection
          anchorTag={en.landingPage.needSomeInspiration.anchorTag}
          header={en.landingPage.needSomeInspiration.header}
          body1={en.landingPage.needSomeInspiration.body}
        >
          <InspirationAccordion />
        </OverviewSection>
        {/* Download Toolkit */}
        <OverviewSection
          anchorTag={en.landingPage.toolkit.anchorTag}
          header={en.landingPage.toolkit.header}
          body1={en.landingPage.toolkit.body}
        >
          <Button variant="outline" onClick={downloadPDFFiles}>
            {en.common.downloadTheToolkit}
          </Button>
        </OverviewSection>
        {/* Create Your Own Systems Map */}
        <OverviewSection
          anchorTag={en.landingPage.createYourOwn.anchorTag}
          header={en.landingPage.createYourOwn.header}
          body1={en.landingPage.createYourOwn.body}
        >
          <Button variant="yellow" onClick={startMapping}>
            {en.common.createYourOwn}
          </Button>
        </OverviewSection>
      </Box>
    </Box>
  );
};

export default MappingToolOverview;

const styles = {
  overviewContainer: {
    overflowY: "auto",
    h: "100%",
    w: "100%",
    mb: "32px",
  },
  titleContainer: {
    borderBottom: "2px solid",
    borderColor: "gray20",
    float: "left",
    mb: "26px",
    ml: "56px",
    mr: "56px",
    textAlign: "left",
  },
};
