import React, { useContext, useState, ChangeEvent } from "react";
import { Box, Button, Flex, Textarea } from "@chakra-ui/react";
import en from "../../lib/en.json";
import { DispatchType } from "./MappingNotes";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

export type Props = {
  dispatch: DispatchType;
  savedNotes: string;
  id: number;
  pdfOutput: boolean;
};

const SelectedNoteBox = ({ dispatch, savedNotes, id, pdfOutput }: Props) => {
  const [notes, setNotes] = useState(savedNotes);
  const { updateNotes } = useContext(SectorContext) as UpdateDataContextType;

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const inputValue = e.target.value;
    setNotes(inputValue);
  };

  const saveNote = () => {
    dispatch({ type: "SAVE_NOTE", payload: notes });
    updateNotes(id, notes);
  };

  return (
    <Box sx={styles.selectedTextBox}>
      <Textarea
        variant="flushed"
        sx={styles.notesTextArea}
        placeholder={en.creationPage.mappingNotes.notesPlaceholder}
        value={notes}
        onChange={handleInputChange}
        maxLength={250}
      />

      {!pdfOutput && (
        <Flex gap="0.5rem">
          <Button variant="yellow" sx={styles.saveButton} onClick={saveNote}>
            {en.common.save}
          </Button>
          <Button
            variant="outline"
            sx={styles.cancelButton}
            onClick={() => dispatch({ type: "CANCEL_EDIT" })}
          >
            {en.common.cancel}
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default SelectedNoteBox;

const styles = {
  selectedTextBox: {
    width: "100%",
    padding: "0.5rem 0.75rem",
    border: "1px solid",
    borderColor: "gray30",
    borderRadius: "0.5rem",
  },
  notesTextArea: {
    border: "none",
    width: "100%",
    height: "1rem",
    textAlign: "left",
    color: "gray80",
    lineHeight: "140%",
    mb: "1.25rem",
    resize: "none",
    padding: "none",
  },
  saveButton: {
    width: "50%",
    borderRadius: "0.5rem",
  },
  cancelButton: {
    width: "50%",
    borderRadius: "0.5rem",
  },
};
