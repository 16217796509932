import React from "react";
import { Box, Text } from "@chakra-ui/react";
import en from "../../lib/en.json";
import { DispatchType } from "./MappingNotes";

type Props = {
  dispatch: DispatchType;
};

const InitialNoteBox = ({ dispatch }: Props) => {
  return (
    <Box 
      sx={styles.initialTextBox}
      onClick={() => dispatch({ type: 'EDIT_NOTE', payload: '' })}
    >
      <Text variant="notes">
        <span style={styles.plusSign}>+ </span>
        {en.creationPage.mappingNotes.addNotes}
      </Text>
    </Box>
  )
};

export default InitialNoteBox;

const styles = {
  plusSign: {
    color: "black",
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "140%",
  },
  initialTextBox: {
    border: "1px dashed",
    borderColor: "gray30",
    borderRadius: "8px",
    textAlign: "left",
    padding: "0.5rem 1rem",
    width: "100%",
    _hover: {
      background: "gray10",
      cursor: "pointer",
    }
  },
};