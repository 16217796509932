import React from "react";
import { Box, Center, Flex, HStack, Text, VStack } from "@chakra-ui/react";
import { scaleOrdinal } from "@visx/scale";
import { Legend, LegendItem, LegendLabel } from "@visx/legend";
import {
  DataIconSmall,
  FundingIconSmall,
  PeopleIconSmall,
  PolicyIconSmall,
  ProductsIconSmall,
} from "../../components/Icons/Icons";
import en from "../../lib/en.json";

const shapeScale = scaleOrdinal<string, React.FC | React.ReactNode>({
  domain: [
    en.common.roles.people,
    en.common.roles.funding,
    en.common.roles.dataAndInformation,
    en.common.roles.productsAndServices,
    en.common.roles.policy,
  ],
  range: [
    <PeopleIconSmall key="people" />,
    <FundingIconSmall key="funding" />,
    <DataIconSmall key="data" />,
    <ProductsIconSmall key="products" />,
    <PolicyIconSmall key="policy" />,
    () => (
      <text key="e" fontSize="0.75rem" dy="1em" dx=".33em" fill="#e0a346">
        $
      </text>
    ),
  ],
});

const legendGlyphSize = 12;

export const RoleLegendVertical = ({ events = false }: { events?: boolean }) => {
  return (
    <Box sx={styles.legendContainer}>
      <Text sx={styles.legendText}>{en.common.legend}</Text>
      <Legend scale={shapeScale}>
        {(labels) => {
          return (
            <VStack align="start">
              {labels.map((label, i) => {
                const shape = shapeScale(label.datum);
                const isValidElement = React.isValidElement(shape);
                return (
                  <LegendItem
                    key={`legend-quantile-${i}`}
                    margin="0 4px 0 0"
                    flexDirection="column"
                    onClick={() => {
                      const { datum, index } = label;
                      if (events) alert(`clicked: ${JSON.stringify({ datum, index })}`);
                    }}
                  >
                    <HStack>
                      <svg width={legendGlyphSize} height={legendGlyphSize}>
                        {isValidElement
                          ? React.cloneElement(shape as React.ReactElement)
                          : React.createElement(shape as React.ComponentType<{ fill: string }>)}
                      </svg>
                      <LegendLabel align="left" margin={0}>
                        <Text fontSize={"0.625rem"}>{label.text}</Text>
                      </LegendLabel>
                    </HStack>
                  </LegendItem>
                );
              })}
              ))
            </VStack>
          );
        }}
      </Legend>
    </Box>
  );
};

export const RoleLegendHorizontal = ({ events = false }: { events?: boolean }) => {
  return (
    <VStack sx={styles.legendPDFContainer}>
      <Center>
        <Text sx={styles.legendText} w="100%">
          {en.common.legend}
        </Text>
      </Center>
      <Legend scale={shapeScale}>
        {(labels) => (
          <HStack align="start">
            {labels.map((label, i) => {
              const shape = shapeScale(label.datum);
              const isValidElement = React.isValidElement(shape);
              return (
                <LegendItem
                  key={`legend-quantile-${i}`}
                  margin="0 4px 0 0"
                  flexDirection="column"
                  onClick={() => {
                    const { datum, index } = label;
                    if (events) alert(`clicked: ${JSON.stringify({ datum, index })}`);
                  }}
                >
                  <VStack>
                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                      {isValidElement
                        ? React.cloneElement(shape as React.ReactElement)
                        : React.createElement(shape as React.ComponentType<{ fill: string }>)}
                    </svg>
                    <LegendLabel align="left" margin={0}>
                      <Flex w="60px" flexWrap="wrap" justify="center" textAlign="center">
                        <Text fontSize={"0.625rem"}>{label.text}</Text>
                      </Flex>
                    </LegendLabel>
                  </VStack>
                </LegendItem>
              );
            })}
            ) )
          </HStack>
        )}
      </Legend>
    </VStack>
  );
};

const styles = {
  legendContainer: {
    border: "1px solid",
    borderColor: "gray10",
    position: "absolute",
    left: "2%",
    p: "0.75rem",
    textAlign: "left",
    top: "82%",
    zIndex: 2,
  },
  legendPDFContainer: {
    border: "1px solid",
    borderColor: "gray10",
    p: "0.75rem",
    textAlign: "left",
  },
  legendText: {
    fontSize: "0.625rem",
    fontWeight: 700,
    lineHeight: "140%",
    mb: "0.5rem",
  },
};
