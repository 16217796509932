import React, { createContext, PropsWithChildren, useState, Dispatch, SetStateAction } from "react";
import { Data, data as mockData } from "../utils/data";
import { useImmer } from "use-immer";

export type UpdateDataContextType = {
  data: Data[];
  setData: Dispatch<SetStateAction<Data[]>>;
  expandedAccordionIndices: number[];
  setExpandedAccordionIndices: Dispatch<SetStateAction<number[]>>;
  handleAccordionChange: (indices: number[]) => void;
  updateRoleSelected: (id: number, role: string) => void;
  updateNotes: (id: number, notes: string) => void;
  resetMap: () => void;
  selectedSector: number | null;
  setSelectedSector: Dispatch<SetStateAction<number | null>>;
  startCreation: boolean;
  setStartCreationActive: Dispatch<SetStateAction<boolean>>;
  updateStrokeWidth: () => void;
  setThickStrokeWidth: (id: number) => void;
  checkIfRolesSelected: (id: number) => boolean;
  resetStrokeWidth: () => void;
  defineIssue: string;
  setDefineIssue: Dispatch<SetStateAction<string>>;
  mapState: string;
  setMapState: Dispatch<SetStateAction<string>>;
};

export const SectorContext = createContext<UpdateDataContextType>({
  data: mockData,
  setData: () => null,
  expandedAccordionIndices: [],
  setExpandedAccordionIndices: () => null,
  handleAccordionChange: () => null,
  updateRoleSelected: () => null,
  updateNotes: () => null,
  resetMap: () => null,
  selectedSector: null,
  setSelectedSector: () => null,
  startCreation: false,
  setStartCreationActive: () => null,
  updateStrokeWidth: () => null,
  setThickStrokeWidth: () => null,
  checkIfRolesSelected: () => false,
  resetStrokeWidth: () => null,
  defineIssue: "",
  setDefineIssue: () => "",
  mapState: "",
  setMapState: () => "",
});

export const SectorContextProvider = ({ children }: PropsWithChildren) => {
  const [selectedSector, setSelectedSector] = useState<number | null>(null);
  const [startCreation, setStartCreationActive] = useState(false);
  const [data, setData] = useImmer(mockData);
  const [expandedAccordionIndices, setExpandedAccordionIndices] = useState<number[]>([]);

  const handleAccordionChange = (indices: number[]) => {
    setExpandedAccordionIndices(indices);
  };
  const [defineIssue, setDefineIssue] = useState<string>("");
  const [mapState, setMapState] = useState<string>("");

  const updateRoleSelected = (id: number, role: string) => {
    setData((draft) => {
      const outerId = Math.floor(id);
      const outerItem = draft.find((item) => item.id === outerId);

      if (outerItem) {
        const sectorToUpdate = outerItem.sectors.find((sector) => sector.id === id);

        if (sectorToUpdate) {
          if (sectorToUpdate.rolesSelected.includes(role)) {
            sectorToUpdate.rolesSelected = sectorToUpdate.rolesSelected.filter((r) => r !== role);
          } else {
            sectorToUpdate.rolesSelected.push(role);
          }
        }
      }
    });
  };

  const updateNotes = (id: number, notes: string) => {
    setData((draft) => {
      const outerId = Math.floor(id);
      const outerItem = draft.find((item) => item.id === outerId);

      if (outerItem) {
        const sectorToUpdate = outerItem.sectors.find((sector) => sector.id === id);

        if (sectorToUpdate) {
          sectorToUpdate.notes = notes;
        }
      }
    });
  };

  const resetMap = () => {
    setData((draft) => {
      draft.forEach((item) => {
        item.sectors.forEach((sector) => {
          sector.rolesSelected = [];
          sector.notes = "";
        });
      });
    });
  };

  const updateStrokeWidth = () => {
    setData((draft) => {
      draft.forEach((donut) => {
        donut.sectors.forEach((sector) => {
          if (sector.rolesSelected.length > 0) {
            donut.strokeWidth = "3px";
          }
        });
      });
    });
  };

  const setThickStrokeWidth = (id: number) => {
    setData((draft) => {
      draft.forEach((donut) => {
        if (donut.id === id) {
          donut.strokeWidth = "3px";
        }
      });
    });
  };

  const checkIfRolesSelected = (id: number) => {
    let rolesHaveBeenSelected = false;

    setData((draft) => {
      draft.forEach((donut) => {
        if (donut.id === id) {
          donut.sectors.forEach((sector) => {
            if (sector.rolesSelected.length > 0) {
              rolesHaveBeenSelected = true;
            }
          });
        }
      });
    });

    return rolesHaveBeenSelected;
  };

  const resetStrokeWidth = () => {
    setData((draft) => {
      draft.forEach((donut) => {
        let donutHasSelectedRoles = false;
        donut.sectors.forEach((sector) => {
          if (sector.rolesSelected.length > 0) {
            donutHasSelectedRoles = true;
          } else if (!donutHasSelectedRoles) {
            donutHasSelectedRoles = false;
          }
        });
        if (!donutHasSelectedRoles) {
          donut.strokeWidth = "1px";
        }
      });
    });
  };

  return (
    <SectorContext.Provider
      value={{
        data,
        setData,
        expandedAccordionIndices,
        setExpandedAccordionIndices,
        handleAccordionChange,
        updateRoleSelected,
        updateNotes,
        resetMap,
        selectedSector,
        setSelectedSector,
        startCreation,
        setStartCreationActive,
        updateStrokeWidth,
        setThickStrokeWidth,
        checkIfRolesSelected,
        resetStrokeWidth,
        defineIssue,
        setDefineIssue,
        mapState,
        setMapState,
      }}
    >
      {children}
    </SectorContext.Provider>
  );
};
