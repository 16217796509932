// Ts no-check is only in this file due to <path d={pie.path(arc)} throwing an error
/* eslint-disable-next-line */
// @ts-nocheck
import React, { useContext } from "react";
import { Flex, Text } from "@chakra-ui/react";
import Pie, { PieArcDatum } from "@visx/shape/lib/shapes/Pie";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import { calculateXAndYOffset } from "../../utils/helpers";

type DonutChartProps = {
  outerRadius: number;
  radius: number;
  startAngle: number;
  animate?: boolean;
  width: number;
};

// Outer 5 Sectors
function DonutChart({ outerRadius, radius, startAngle, width }: DonutChartProps) {
  const { data } = useContext(SectorContext) as UpdateDataContextType;
  const ninetyDegreesInRadians = 1.5708;

  function calculateXAndY(midAngle: number, labelWidth: number) {
    // Find base coordinates
    let largerRadius: number;
    if (width > 935) {
      largerRadius = radius * 1.1;
    } else if (width < 650) {
      largerRadius = radius * 1.125;
    } else {
      largerRadius = radius * 1.15;
    }
    const baseXCoordinate = largerRadius * Math.cos(midAngle - ninetyDegreesInRadians);
    const baseYCoordinate = largerRadius * Math.sin(midAngle - ninetyDegreesInRadians);
    const { x, y } = calculateXAndYOffset(45, labelWidth, baseXCoordinate, baseYCoordinate);
    return { x, y };
  }

  function calculateModifiedDimensions(labelWidth: number, strokeWidth: string) {
    // We are re-rendering the chart on the Download Systems Map modal
    // The hardcoded sizes need to be modified to fit the modal
    let modifiedLabelWidth, modifiedHeight, fontSize: number;
    let modifiedStrokeWidth: string;
    if (width < 650) {
      modifiedLabelWidth = labelWidth / 1.75;
      modifiedHeight = 45 / 1.5;
      modifiedStrokeWidth = "0.25px";
      fontSize = "0.5rem";
    } else {
      modifiedLabelWidth = labelWidth;
      modifiedHeight = 45;
      modifiedStrokeWidth = strokeWidth;
      fontSize = "0.875rem";
    }
    return { modifiedLabelWidth, modifiedHeight, modifiedStrokeWidth, fontSize };
  }

  /* eslint-disable-next-line */
  function midAngle(d: PieArcDatum<any>) {
    return d.startAngle + (d.endAngle - d.startAngle) / 2;
  }

  return (
    <Pie
      data={data}
      pieValue={(d) => d.value}
      outerRadius={radius}
      innerRadius={radius - outerRadius}
      padAngle={0.05}
      startAngle={startAngle}
      pieSort={(a, b) => a.id - b.id}
    >
      {(pie) => {
        return pie.arcs.map((arc) => {
          const { data } = arc;
          const { baseColor, labelColor, labelWidth, strokeWidth, title } = data;
          const { modifiedHeight, modifiedLabelWidth, modifiedStrokeWidth, fontSize } =
            calculateModifiedDimensions(labelWidth, strokeWidth);
          const middleAngle = midAngle(arc);
          const { x, y } = calculateXAndY(middleAngle, modifiedLabelWidth);
          const arcShadow = `drop-shadow(0px 0px 12px ${arc.data.sectors[0].shadowRGBA})`;

          return (
            <g key={data.title}>
              <path
                d={pie.path(arc)}
                fill={baseColor}
                stroke={baseColor}
                strokeWidth={modifiedStrokeWidth}
                filter={arcShadow}
              />
              <foreignObject
                x={x}
                y={y}
                style={{
                  width: modifiedLabelWidth,
                  height: modifiedHeight,
                  textAlign: "center",
                  flexWrap: "wrap",
                }}
              >
                <Flex sx={styles.textBox} align="center">
                  <Text color={labelColor} fontSize={fontSize} sx={styles.label}>
                    {title}
                  </Text>
                </Flex>
              </foreignObject>
            </g>
          );
        });
      }}
    </Pie>
  );
}

export default DonutChart;

const styles = {
  textBox: {
    height: "100%",
  },
  label: {
    fontWeight: 700,
    letterSpacing: "0.096px",
  },
};
