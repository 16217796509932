import React, { useState } from "react";
import en from "../../lib/en.json";
import { Box, Button, Text } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { OverviewSection } from "../../design-system";
import { RoleDescriptions } from "../index";

const Tutorial = () => {
  const [rolesExpanded, setRolesExpanded] = useState(false);

  const textWithSpan = (
    <Box>
      <Text as="span">{en.landingPage.howToUseThisTool.body.paragraph1} </Text>
      <Text as="span" fontWeight={700}>
        {en.landingPage.howToUseThisTool.body.paragraph1span}
      </Text>
      <Text as="span"> {en.landingPage.howToUseThisTool.body.paragraph1AfterSpan}</Text>
    </Box>
  );

  return (
    <>
      <OverviewSection
        anchorTag={en.landingPage.howToUseThisTool.anchorTag}
        header={en.landingPage.howToUseThisTool.header}
        body1={textWithSpan}
        body2={en.landingPage.howToUseThisTool.body.paragraph2}
      >
        {!rolesExpanded && (
          <Button
            rightIcon={<ChevronDownIcon />}
            variant="plainText"
            onClick={() => setRolesExpanded(true)}
          >
            {en.common.readMore}
          </Button>
        )}
        {rolesExpanded && (
          <>
            <RoleDescriptions />
            <Button
              rightIcon={<ChevronUpIcon />}
              variant="plainText"
              onClick={() => setRolesExpanded(false)}
            >
              {en.common.collapse}
            </Button>
          </>
        )}
      </OverviewSection>
    </>
  );
};

export default Tutorial;
