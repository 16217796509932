import React from "react";
import RoleItem from "./RoleItem";
import { roleDescriptionItems } from "../../lib/constants";

const RoleDescriptions = () => {
  return (
    <>
      {roleDescriptionItems.map((role) => {
        return (
          <RoleItem
            headerText={role.headerText}
            descriptionText={role.descriptionText}
            icon={role.icon}
            key={role.headerText}
          />
        );
      })}
    </>
  );
};

export default RoleDescriptions;
