import React from "react";
import { HStack } from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import AnchorLink, { 
  Props as AnchorLinkProps
} from "../../atoms/AnchorLink/AnchorLink";

export interface Props {
  navLinks: AnchorLinkProps[];
}

const BackAnchorLinkNav = ({ navLinks }: Props) => {
  return (
    <HStack sx={styles.navigation}>
      <span>
        <ArrowBackIcon onClick={() => window.history.back()}/>
        {navLinks.map((link) => {
          return <AnchorLink href={link.href} text={link.text} key={link.text} />;
        })}
      </span>
    </HStack>
  );
};

export default BackAnchorLinkNav;

const styles = {
  navigation: {
    backgroundColor: "white",
    borderBottom: "2px solid",
    borderColor: "gray20",
    height: "2.75rem",
    ml: "56px",
    mr: "56px",
    position: "sticky",
    top: 0,
    "& > *:hover": {
      borderBottom: "2px solid",
      borderColor: "black",
      mb: "-10px",
      pb: "8px",
    },
  }
};
