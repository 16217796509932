import en from "../lib/en.json";

export const ehrRoles = [
  {
    id: 1,
    strokeWidth: "1px",
    sectors : [
      {
        id: 1.1,
        rolesSelected: [
          en.common.roles.people,
          en.common.roles.funding,
          en.common.roles.dataAndInformation,
          en.common.roles.productsAndServices,
          en.common.roles.policy,
        ],
      },
      {
        id: 1.2,
        rolesSelected: [
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
      {
        id: 1.4,
        rolesSelected: [
          en.common.roles.dataAndInformation,
        ],
      },
    ]
  },
  {
    id: 2,
    strokeWidth: "1px",
    sectors: [
      {
        id: 2.1,
        rolesSelected: [
          en.common.roles.people,
        ],
      },
    ]
  },
  {
    id: 3,
    strokeWidth: "1px",
    sectors: [
      {
        id: 3.1,
        rolesSelected: [
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
      {
        id: 3.2,
        rolesSelected: [
          en.common.roles.funding,
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
      {
        id: 3.3,
        rolesSelected: [
          en.common.roles.funding,
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
    ]
  },
  {
    id: 4,
    strokeWidth: "1px",
    sectors: [
      {
        id: 4.1,
        rolesSelected: [
          en.common.roles.funding,
        ],
      },
      {
        id: 4.2,
        rolesSelected: [
          en.common.roles.people,
          en.common.roles.funding,
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
    ]
  },
  {
    id: 5,
    strokeWidth: "1px",
    sectors: [
      {
        id: 5.1,
        rolesSelected: [
          en.common.roles.dataAndInformation,
        ],
      },
      {
        id: 5.2,
        rolesSelected: [
          en.common.roles.dataAndInformation,
          en.common.roles.policy,
        ],
      },
      {
        id: 5.3,
        rolesSelected: [
          en.common.roles.dataAndInformation,
        ],
      }
    ]
  },
]