import React from "react";
import PropTypes from "prop-types";
import { Group } from "@visx/group";
import DonutChart from "../DonutChart/DonutChart";
import PieChart from "../PieChart/PieChart";
import { RoleLegendVertical } from "../RoleLegend/RoleLegend";
import ContinuumOfCare from "../ContinuumOfCare/ContinuumOfCare";
import { InnerSectorData } from "../../utils/data";

const defaultMargin = { top: 20, right: 20, bottom: 50, left: 20 };

interface Props {
  width: number;
  height: number;
  margin?: typeof defaultMargin;
  outerPieData: InnerSectorData[];
  downloadModal: boolean;
}

//Parent Container to render outer donut and inner PieChart to render the charts on top of one another
function CombinationDonutPieChart({
  width,
  height,
  margin = defaultMargin,
  outerPieData,
  downloadModal,
}: Props) {
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const outerPieRadius = radius * 0.75;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const outerPieOuterRadius = outerPieRadius * 1.25;
  const startingAngle = -0.401426;

  if (width < 10) return null;

  return (
    <div style={{ position: "relative" }}>
      <svg width={width} height={height}>
        <Group top={centerY} left={centerX}>
          <DonutChart outerRadius={1} radius={radius} startAngle={startingAngle} width={width} />
          <PieChart
            radius={outerPieRadius}
            startAngle={startingAngle}
            data={outerPieData}
            downloadModal={downloadModal}
            outerRadius={outerPieOuterRadius}
            width={width}
          />
          <ContinuumOfCare downloadModal={downloadModal} />
        </Group>
      </svg>
      {!downloadModal && <RoleLegendVertical />}
    </div>
  );
}

CombinationDonutPieChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  margin: PropTypes.object,
  donutData: PropTypes.arrayOf(PropTypes.object),
  innerPieData: PropTypes.arrayOf(PropTypes.object),
  outerPieData: PropTypes.arrayOf(PropTypes.object),
};
export default CombinationDonutPieChart;
