import React, { PropsWithChildren, ReactNode } from "react";
import { Box, Text } from "@chakra-ui/react";

type Props = {
  anchorTag?: string;
  header: string;
  body1: string | ReactNode;
  body2?: string;
};

const OverviewSection = ({
  anchorTag,
  header,
  body1,
  body2,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <Box sx={styles.container} id={anchorTag}>
      <Text variant="header1">{header}</Text>
      {(body1 as ReactNode) ? <Box>{body1}</Box> : <Text variant="paragraph1">{body1}</Text>}
      {body2 && <Text variant="paragraph1">{body2}</Text>}
      {children && <Box sx={styles.childrenContainer}>{children}</Box>}
    </Box>
  );
};

export default OverviewSection;

const styles = {
  container: {
    m: "0px 56px 32px 56px",
    textAlign: "left",
    _last: {
      mb: "96px",
    },
  },
  childrenContainer: {
    mt: "1rem",
  },
};
