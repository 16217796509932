import React, { useContext, useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
} from "@chakra-ui/react";
import { ArrowBackIcon } from "@chakra-ui/icons";
import en from "../../lib/en.json";
import { AnchorLink } from "../../design-system";
import CombinationDonutPieChart from "../../charts/CombinationDonutPieChart/CombinationDonutPieChart";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import PDFOutput from "../PDFOutput/PDFOutput";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { InnerSectorData } from "../../utils/data";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  handleWarningModal: () => void;
  createMultiPagePDF: () => Promise<void>;
  isDownloading: boolean;
};

const DownloadModal = ({
  isOpen,
  onClose,
  handleWarningModal,
  createMultiPagePDF,
  isDownloading,
}: Props) => {
  const { data, mapState } = useContext(SectorContext) as UpdateDataContextType;
  const contentRef = useRef(null);

  const sectors = data.flatMap((sector) => sector.sectors);
  sectors.shift();

  const filteredNotesArray = sectors.filter((item) => item.notes.length > 0);

  const chunkSize = 3;
  const chunkedNotesArray: InnerSectorData[][] = [];

  for (let i = 0; i < filteredNotesArray.length; i += chunkSize) {
    const chunk = filteredNotesArray.slice(i, i + chunkSize);
    chunkedNotesArray.push(chunk);
  }

  const innerSectorData = data.flatMap((sector) => sector.sectors);
  return (
    <Box>
      <Modal blockScrollOnMount={true} isOpen={isOpen} size="full" onClose={onClose}>
        <ModalOverlay />
        <ModalContent sx={styles.modalContainer}>
          <Text variant="upperCaseHeader">{mapState}</Text>
          <ModalHeader padding="0">
            <Text variant="header1">{en.modal.downloadTitle}</Text>
            <Text variant="paragraph3">{en.modal.downloadDescription}</Text>
            <Text paddingTop="10px" variant="sourceLink">
              <AnchorLink href="" text="Download" download={true}></AnchorLink>
              {en.modal.downloadSubDescription} <span>&#x21a1;</span>
            </Text>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody sx={styles.modalBody}>
            <Box sx={styles.goBackLink}>
              <Text onClick={onClose} variant="goBackEdit">
                <ArrowBackIcon mr="5px" mb="2px" />
                {en.modal.edit}
              </Text>
            </Box>
            <Box sx={styles.downloadChartBody}>
              <ParentSize>
                {({ width, height }) => (
                  <CombinationDonutPieChart
                    width={width}
                    height={height}
                    donutData={data}
                    outerPieData={innerSectorData}
                    downloadModal
                  />
                )}
              </ParentSize>
            </Box>
          </ModalBody>

          <ModalFooter sx={styles.modalFooter}>
            <Button
              onClick={createMultiPagePDF}
              variant="yellowXL"
              isLoading={isDownloading}
              loadingText={en.common.downloading}
            >
              {en.modal.downloadButton}
            </Button>
            <Button onClick={() => handleWarningModal()} variant="outlineXL">
              {en.common.close}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Box mt="50px">
        <PDFOutput contentRef={contentRef} chunkedNotesArray={chunkedNotesArray} />
      </Box>
    </Box>
  );
};

export default DownloadModal;

const styles = {
  modalContainer: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    gap: "10px",
    padding: "48px",
    width: "649px",
  },
  modalBody: {
    alignItems: "left",
    display: "flex",
    flexDirection: "column",
    padding: "10px 0px",
  },
  goBackLink: {
    borderBottom: "0.5px solid ",
    borderColor: "gray60",
    marginBottom: "10px",
    width: "117px",
  },
  downloadChartBody: {
    border: "1px solid",
    borderColor: "gray20",
    height: "100%",
    width: "100%",
  },
  modalFooter: {
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    padding: "0",
  },
};
