import React, { Dispatch, useContext, useReducer, useEffect } from "react";
import { Box, HStack, Text } from "@chakra-ui/react";
import InitialNoteBox from "./InitialNoteBox";
import SavedNoteBox from "./SavedNoteBox";
import SelectedNoteBox from "./SelectedNoteBox";
import { NoteAction, noteReducer } from "./noteReducer";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import { InfoIcon } from "../Icons/Icons";
import en from "../../lib/en.json";

export type DispatchType = Dispatch<NoteAction>;

export type Props = {
  id: number;
  pdfOutput: boolean;
};

const NoteInfoText = () => {
  return (
    <Box p="8px">
      <HStack spacing="8px">
        <InfoIcon w="18px" h="18px" />
        <Text variant={"upperCaseHeaderGray"}>{en.creationPage.mappingNotes.notesInfoText}</Text>
      </HStack>
    </Box>
  );
};

const MappingNotes = ({ id, pdfOutput }: Props) => {
  const { data } = useContext(SectorContext) as UpdateDataContextType;
  const [state, dispatch] = useReducer(noteReducer, { notes: "", editMode: false });

  useEffect(() => {
    const outerId = parseInt(id.toString().charAt(0));
    const outerItem = data.find((item) => item.id === outerId);
    let currentNotesInContext = "";

    if (outerItem) {
      const sector = outerItem.sectors.find((sector) => sector.id === id);
      if (sector) {
        currentNotesInContext = sector.notes;
      }
    }

    if (state.notes !== currentNotesInContext) {
      dispatch({ type: "SYNC_NOTE", payload: currentNotesInContext });
    }
  }, [data, id, state.notes]);

  return (
    <Box sx={styles.mappingNotesBox}>
      {!state.notes && !state.editMode && <InitialNoteBox dispatch={dispatch as DispatchType} />}
      {state.editMode && (
        <>
          <SelectedNoteBox
            dispatch={dispatch as DispatchType}
            savedNotes={state.notes}
            id={id}
            pdfOutput={pdfOutput}
          />
          {!pdfOutput && <NoteInfoText />}
        </>
      )}
      {state.notes && !state.editMode && (
        <>
          <SavedNoteBox
            dispatch={dispatch as DispatchType}
            notes={state.notes}
            id={id}
            pdfOutput={pdfOutput}
          />
          {!pdfOutput && <NoteInfoText />}
        </>
      )}
    </Box>
  );
};

export default MappingNotes;

const styles = {
  mappingNotesBox: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "0.5rem",
  },
};
