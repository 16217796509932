import React, { useContext } from "react";
import { Accordion } from "@chakra-ui/react";
import { data } from "../../utils/data";
import CreationAccordionSector from "./CreationAccordionSector";
import { SectorContext } from "../../contexts/SectorContext";

const CreationAccordion = () => {
  const { expandedAccordionIndices, handleAccordionChange } = useContext(SectorContext);
  const copyArray = [...data];
  copyArray.reverse();

  return (
    <Accordion
      sx={styles.creationAccordionContainer}
      index={expandedAccordionIndices}
      onChange={handleAccordionChange}
      allowMultiple
    >
      {copyArray.map((item) => {
        if (item.title !== "") {
          return (
            <CreationAccordionSector
              id={item.id}
              title={item.title}
              key={item.title}
              sectors={item.sectors}
            />
          );
        }
      })}
    </Accordion>
  );
};

export default CreationAccordion;

const styles = {
  creationAccordionContainer: {
    borderColor: "gray80",
    margin: "0px 56px 32px 56px",
  },
};
