import React from "react";
import { Image } from "@chakra-ui/react";

export const Logo = () => {
  return (
    <Image src={require("../../assets/images/tkf_logo.png")} htmlWidth="165px" htmlHeight="48px" />
  );
};

export const ColorLogo = () => {
  return (
    <Image
      src={require("../../assets/images/tkf_logo_color.png")}
      htmlWidth="165px"
      htmlHeight="48px"
    />
  );
};
