import React from "react";
import { Accordion } from "@chakra-ui/react";
import InspirationAccordionItem from "./InspirationAccordionItem";
import { inspirationAccordionItems } from "../../lib/constants";

const InspirationAccordion = () => {
  return (
    <Accordion allowMultiple mb="32px" mt="16px">
        {inspirationAccordionItems.map((item) => {
          return (
            <InspirationAccordionItem
              headerText={item.headerText}
              descriptionText={item.descriptionText}
              keyIssueAreaItems={item.keyIssueAreaItems}
              key={item.headerText}
            />
          );
        })}
    </Accordion>
  );
};

export default InspirationAccordion;
