import React, { useContext } from "react";
import {
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
  Text,
} from "@chakra-ui/react";
import RoleTags from "../RoleTags/RoleTags";
import MappingNotes from "../MappingNotes/MappingNotes";
import { InnerSectorData } from "../../utils/data";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";

export type Props = {
  id: number;
  title: string;
  sectors: InnerSectorData[];
};

const CreationAccordionSector = ({ id, title, sectors }: Props) => {
  const { updateStrokeWidth, checkIfRolesSelected, setThickStrokeWidth, resetStrokeWidth } =
    useContext(SectorContext) as UpdateDataContextType;

  const setStrokeWidth = (isExpanded: boolean) => {
    if (!isExpanded) {
      setThickStrokeWidth(id);
    } else {
      const rolesHaveBeenSelected = checkIfRolesSelected(id);

      if (rolesHaveBeenSelected) {
        updateStrokeWidth();
      } else {
        resetStrokeWidth();
      }
    }
  };

  return (
    <AccordionItem sx={styles.creationAccordionSectorButton}>
      {({ isExpanded }) => (
        <>
          <h2>
            <AccordionButton minHeight="52px" onClick={() => setStrokeWidth(isExpanded)}>
              <Box as="span" flex="1" textAlign="left">
                <Text sx={styles.title}>{title}</Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {sectors.map((item) => {
              return (
                <Box sx={styles.sectorContainer} key={item.title}>
                  <Text>{item.title}</Text>
                  <RoleTags id={item.id} />
                  <MappingNotes id={item.id} pdfOutput={false} />
                </Box>
              );
            })}
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
};

export default CreationAccordionSector;

const styles = {
  title: {
    fontSize: "1rem",
    fontWeight: 700,
    lineHeight: "100%",
  },
  sectorContainer: {
    border: "1px solid",
    borderColor: "gray10",
    borderRadius: "8px",
    display: "flex",
    mb: "0.5rem",
    padding: "8px",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: "8px",
    alignSelf: "stretch",
  },
  creationAccordionSectorButton: {
    ":first-of-type": {
      border: "none",
    },
  },
};
