import React from "react";
import { Box, ButtonGroup, Flex, ComponentWithAs, IconProps } from "@chakra-ui/react";
import { roleDescriptionItems } from "../../lib/constants";
import RoleTagsItem from "./RoleTagsItem";

type RoleTagProps = {
  role: string;
  icon: ComponentWithAs<"svg", IconProps>;
  id: number;
};

export type Props = {
  id: number;
  roleTagItems?: RoleTagProps[];
};

const RoleTags = ({ id }: Props) => {
  return (
    <Box mt="0.5rem" width={"100%"}>
      <Flex>
        <ButtonGroup flexWrap="wrap" variant="roleTags" spacing="0">
          {roleDescriptionItems.map((roleTag) => {
            return (
              <RoleTagsItem
                role={roleTag.headerText}
                icon={roleTag.icon}
                id={id}
                key={roleTag.headerText}
              />
            );
          })}
        </ButtonGroup>
      </Flex>
    </Box>
  );
};

export default RoleTags;
