import React, { useContext } from "react";
import { Box, Text, OrderedList, ListItem, UnorderedList } from "@chakra-ui/react";
import { backNavigationLinks } from "../../lib/constants";
import BackAnchorLinkNav from "../../design-system/molecules/BackAnchorLinkNav/BackAnchorLinkNav";
import { SectorContext, UpdateDataContextType } from "../../contexts/SectorContext";
import en from "../../lib/en.json";

export type Props = {
  title: string;
  stakeholders: string[];
};

const ContinuumOfCareDetails = () => {
  return (
    <Box sx={styles.sectorDetailsContainer}>
      <Text variant="header1">{en.landingPage.sectorDetails.continuumOfCare.title}</Text>
      <Text variant="body1" mb="2rem">
        {en.landingPage.sectorDetails.continuumOfCare.body.description}
      </Text>
      <UnorderedList p="0.25rem">
        {en.landingPage.sectorDetails.continuumOfCare.body.details.map((detail: string) => {
          return <ListItem key={detail}>{detail}</ListItem>;
        })}
      </UnorderedList>
    </Box>
  );
};

const SectorDetails = () => {
  const { data, selectedSector } = useContext(SectorContext) as UpdateDataContextType;
  const outerSectorId = Math.floor(selectedSector as number);
  const outerSector = data.find((sector) => sector.id === outerSectorId);
  const innerSector = outerSector?.sectors.find((sector) => sector.id === selectedSector);

  const setFirstLetterToUpperCase = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <BackAnchorLinkNav navLinks={backNavigationLinks} />
      {selectedSector !== 1000 ? (
        <Box sx={styles.sectorDetailsContainer}>
          <Text variant="header1">{innerSector?.title}</Text>
          {/* This sector contains the following: */}
          <Text variant="body1">{en.sectorDetails.listLabel}</Text>
          <OrderedList p="0.25rem">
            {innerSector?.stakeholders?.map((stakeholder) => {
              return (
                <ListItem key={stakeholder}>{setFirstLetterToUpperCase(stakeholder)}</ListItem>
              );
            })}
          </OrderedList>
        </Box>
      ) : (
        <ContinuumOfCareDetails />
      )}
    </>
  );
};

export default SectorDetails;

const styles = {
  sectorDetailsContainer: {
    textAlign: "left",
    margin: "2rem 3.5rem",
    pl: "0.5rem",
  },
};
